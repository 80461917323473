// 静态地图各省统偏移量计算
export default {
  "北京市": { tx: 0.1240, ty: -0.133, transformX: -1, transformY: -1 },
  "天津市": { tx: 0.1353, ty: -0.11, transformX: -1, transformY: -1 },
  "河北省": { tx: 0.1128, ty: -0.1, transformX: -1, transformY: -1 },
  "山西省": { tx: 0.0733, ty: -0.0834, transformX: -1, transformY: -1 },
  "内蒙古自治区": { tx: 0.0676, ty: -0.1669, transformX: -1, transformY: -1 },
  "辽宁省": { tx: 0.2048, ty: -0.1502, transformX: -1, transformY: -1 },
  "吉林省": { tx: 0.2312, ty: -0.1936, transformX: -1, transformY: -1 },
  "黑龙江省": { tx: 0.2462, ty: -0.2604, transformX: -1, transformY: -1 },
  "上海市": { tx: 0.1842, ty: 0.0751, transformX: -1, transformY: -1 },
  "江苏省": { tx: 0.1635, ty: 0.0434, transformX: -1, transformY: -1 },
  "浙江省": { tx: 0.1654, ty: 0.1102, transformX: -1, transformY: -1 },
  "安徽省": { tx: 0.1297, ty: 0.0634, transformX: -1, transformY: -1 },
  "福建省": { tx: 0.1466, ty: 0.1936, transformX: -1, transformY: -1 },
  "江西省": { tx: 0.1123, ty: 0.1302, transformX: -1, transformY: -1 },
  "山东省": { tx: 0.1362, ty: -0.0534, transformX: -1, transformY: -1 },
  "河南省": { tx: 0.0850, ty: -0.0166, transformX: -1, transformY: -1 },
  "湖北省": { tx: 0.0885, ty: 0.0667, transformX: -1, transformY: -1 },
  "湖南省": { tx: 0.0697, ty: 0.1268, transformX: -1, transformY: -1 },
  "广东省": { tx: 0.0782, ty: 0.2370, transformX: -1, transformY: -1 },
  "广西壮族自治区": { tx: 0.0, ty: 0.2370, transformX: -1, transformY: -1 },
  "海南省": { tx: 0.0225, ty: 0.3138, transformX: -1, transformY: -1 },
  "重庆市": { tx: -0.0094, ty: 0.0834, transformX: -1, transformY: -1 },
  "四川省": { tx: -0.0488, ty: 0.0634, transformX: -1, transformY: -1 },
  "贵州省": { tx: -0.0169, ty: 0.1469, transformX: -1, transformY: -1 },
  "云南省": { tx: -0.0789, ty: 0.1869, transformX: -1, transformY: -1 },
  "西藏自治区": { tx: -0.2180, ty: 0.0500, transformX: -1, transformY: -1 },
  "陕西省": { tx: 0.0263, ty: -0.0200, transformX: -1, transformY: -1 },
  "甘肃省": { tx: -0.0338, ty: -0.0667, transformX: -1, transformY: -1 },
  "青海省": { tx: -0.0864, ty: -0.0868, transformX: -1, transformY: -1 },
  "宁夏回族自治区": { tx: 0.0, ty: -0.100, transformX: -1, transformY: -1 },
  "新疆维吾尔自治区": { tx: -0.2086, ty: -0.2604, transformX: -1, transformY: -1 },
  "台湾省": { tx: 0.1766, ty: 0.2337, transformX: -1, transformY: -1 },
  "香港特别行政区": { tx: 0.0882, ty: 0.2670, transformX: -1, transformY: -1 },
  "澳门特别行政区": { tx: 0.0682, ty: 0.2670, transformX: -1, transformY: -1 },
};
// 地图省会经纬度
export const centerLongLatitudes = {
  "北京市": [116.38,39.87],
  "天津市": [117.20,39.09],
  "河北省": [114.51,38.04],
  "山西省": [112.55,37.87],
  "内蒙古自治区": [111.75,40.84],
  "辽宁省": [123.46,41.68],
  "吉林省": [125.32,43.82],
  "黑龙江省": [126.54,45.80],
  "上海市": [121.46,31.25],
  "江苏省": [118.80,32.06],
  "浙江省": [120.21,30.25],
  "安徽省": [117.23,31.82],
  "福建省": [119.30,26.07],
  "江西省": [115.86,28.68],
  "山东省": [117.12,36.65],
  "河南省": [113.66,34.75],
  "湖北省": [114.30,30.59],
  "湖南省": [112.94,28.23],
  "广东省": [113.26,23.13],
  "广西壮族自治区": [108.37,22.82],
  "海南省": [110.20,20.05],
  "重庆市": [106.55,29.56],
  "四川省": [104.14,30.63],
  "贵州省": [106.63,26.65],
  "云南省": [102.83,24.88],
  "西藏自治区": [91.17,29.65],
  "陕西省": [108.94,34.34],
  "甘肃省": [103.93,35.91],
  "青海省": [101.77,36.62],
  "宁夏回族自治区": [106.23,38.49],
  "新疆维吾尔自治区": [87.68,43.85],
  "台湾省": [121.51,25.04],
  "香港特别行政区": [114.29,22.26],
  "澳门特别行政区": [113.54,22.18],
};

// 所有省code
export const provinceCodes = {
  "北京市": "110000",
  "天津市": "120000",
  "河北省": "130000",
  "山西省": "140000",
  "内蒙古自治区": "150000",
  "辽宁省": "210000",
  "吉林省": "220000",
  "黑龙江省": "230000",
  "上海市": "310000",
  "江苏省": "320000",
  "浙江省": "330000",
  "安徽省": "340000",
  "福建省": "350000",
  "江西省": "360000",
  "山东省": "370000",
  "河南省": "410000",
  "湖北省": "420000",
  "湖南省": "430000",
  "广东省": "440000",
  "广西壮族自治区": "450000",
  "海南省": "460000",
  "重庆市": "500000",
  "四川省": "510000",
  "贵州省": "520000",
  "云南省": "530000",
  "西藏自治区": "540000",
  "陕西省": "610000",
  "甘肃省": "620000",
  "青海省": "630000",
  "宁夏回族自治区": "640000",
  "新疆维吾尔自治区": "650000",
  "台湾省": "710000",
  "香港特别行政区": "810000",
  "澳门特别行政区": "820000",
}
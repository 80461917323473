import axios from 'axios';
import Vue from 'vue';
import {api } from './config';
import { downloadFile } from './util'

axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.get['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.put['Content-Type'] = 'application/json;charset=utf-8';

axios.defaults.baseURL = api.HttpUrl
const backLogin = () => {
    setTimeout(() => {
        app.$router.replace({ path: "/Login", query: {} });
    }, 1000);
};
// 添加request拦截
axios.interceptors.request.use(
    (config) => {

        let token = localStorage.getItem("brjYunWeiFactoryTiken");
        if (!token && config.url != '/auth-service/auth/login') {
            backLogin();
        } else {
            config.headers["Authorization"] = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    if (response.status == 200) {
        if(response.data.code != 1) {
            // 数据请求失败
            // Token过期重新登录
            if (response.data.exceptionCode == "20015" || response.data.exceptionCode == "20011") {
                backLogin();
            }
        }
        if (response.data.code == 0) {
            // 没有访问权限或token过期
            if (response.data.exceptionCode == "20015" || response.data.exceptionCode == "20011") {
                console.log('前面已跳转到backLogin,有可能code为很多值');
            } else if (response.data.exceptionCode == '70001') {
                // backLogin();
                Vue.prototype.$message.warning(response.data.exceptionMsg || '系统繁忙');
            } else {
                Vue.prototype.$message.warning(response.data.exceptionMsg || '系统繁忙');
            }
        }
    } else {
        Vue.prototype.$message.warning(response.statusText);
    }
    return response;
}, function (error) {
    if (error.response.status == 401) {
        backLogin();
    } else {
        Vue.prototype.$message.warning('网络繁忙');
    }
    return Promise.reject(error);
});

//登录的特殊方法
export function login(url, parms, callback) {
    axios.post(url,parms,{}).then(function (res) {
        if (res.data.code == 1) {
            localStorage.setItem("brjYunWeiFactoryTiken", res.data.data);
            callback(res);
        }
    });
}

//post 请求方式
export function postFun(url, parms, callback) {
    return new Promise((resolve, reject) => {
        const loading = Vue.prototype.$loading(
            {
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0)'
            }
        );
        axios.post(url, parms).then(function (res) {
            loading.close(res);
            if (res.status == 200) {
                if(res.data.code == 1) {
                    callback(res.data);
                    resolve(res.data);
                }else{
                    callback(res.data);
                    resolve(res.data);
                }
            } else {
                
                reject('error');
            }
        }).catch(function (err) {
            reject(err);
            loading.close();
        });
    });
}

//PUT 请求方式
export function putFun(url, parms, callback) {
    return new Promise((resolve, reject) => {
        const loading = Vue.prototype.$loading(
            {
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0)'
            }
        );
        axios.put(url, parms).then(function (res) {
            loading.close();
            if (res.status == 200) {
                if (res.data.code == 1) {
                   callback(res.data);
                   resolve(res.data);
                } else {
                    callback(res.data);
                    resolve(res.data);
                    reject('error');
                }
            }
        }).catch(function (err) {
            loading.close();
            reject(err);
        });  
    });
}

//get 请求方式
export function getFun(url, parms, callback) {
    return new Promise((resolve, reject) => {
        const loading = Vue.prototype.$loading(
            {
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0)'
            }
        );
        let parm = ''
        for (let it in parms) {
            parm += "&" + it + '=' + parms[it]
        }
        let newUrl = parm ? url+'?'+parm.substr(1) : url;
        axios.get(newUrl).then(function (res) {
            loading.close();
            callback(res.data);
            resolve(res.data);
        }).catch(function (err) {
            callback(err);
            resolve(err);
            reject(err);
            loading.close();
        });  
    });
}

//DELET 请求方式
export function deleteFun(url, parms, callback) {
    return new Promise((resolve, reject) => {
        const loading = Vue.prototype.$loading(
            {
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0)'
            }
        );
        let parm = ''
        for (let it in parms) {
            parm += "&" + it + '=' + parms[it]
        }
        let newUrl = url + (parm ? '?' + parm.substr(1) : '');
        axios.delete(newUrl).then(function (res){
            loading.close();
            if(res.status == 200) {
                if (res.data.code == 1) {
                   callback(res.data);
                   resolve(res.data);
                } else {
                    callback(res.data);
                    resolve(res.data);
                    reject('error');
                }
            }
        }).catch(function (err) {
            reject(err);
            loading.close();
        });
    });
}

//上传EXCLE
//导入EXCLE表格
export function uploadExcle(ev, url, callback) {
    let file = ev.target.files[0];
    //键值对
    let request = new FormData();
    request.append("file", file);
    axios({
        method: 'post',
        headers:{'Authorization':localStorage.getItem("brjYunWeiFactoryTiken")},
        url: url,
        data:request,
    }).then(function (res) {
        console.log("导入成功的",res);
        callback(res)
    });
    // console.log("键值对的值",request)
    // postFun(url,request,res=>{
    //    console.log("导入的结果",res)
    // })
}

//导出文件
export function exportFun(url,param,textName){
    axios({
        method:'post',
        headers: { 'Authorization':localStorage.getItem('YunWeiTikenBRJ') },
        responseType: 'arraybuffer',
        url:url,
        data:param
      }).then(res=>{
        if(!!res && res.status == 200){
            downloadFile(res.data,textName,'xlsx')
        }else if(!!res){
           Vue.prototype.$message.warning(res.message)
        }
      }).catch(err=>{
           Vue.prototype.$message.warning(err.message);
      })
}
<template>
  <div class="common-width common-height">
     <router-view />
  </div>
</template>

<script>
import {getWindowMess} from '@/utils/util'
export default {
  name: 'App',
  created(){
     setInterval(res=>{
         getWindowMess()
     },10)
  },
}
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}

.el-form-item>.el-form-item__label:before {
  content: ' ';
  color: #F56C6C;
  margin-right: 10px;
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: 'PingFang SC';
  font-size: 0.875rem;
}
:not(:root):fullscreen::backdrop {
  background-color: #fff;
}

.common-display {
  display: flex;
  justify-items: center;
}

.common-width {
  width: 100%;
}

.common-widthhalf {
  width: 50%;
}

.common-height {
  height: 100%;
}
.common-imgborder{
		border:1px solid rgba(173, 173, 173,0.3);
}
.common-flex {
  display: flex;
}

.common-flexw {
  flex: 1;
}

.common-textCenter {
  text-align: center;
}

.common-space-around {
  display: flex;
  justify-content: space-around;
}

.common-space-between {
  display: flex;
  justify-content: space-between;
}

.common-items-center {
  display: flex;
  align-items: center;
}

.common-relatives {
  position: relative;
}

.common-absolute {
  position: absolute;
}

.common-wrap {
  flex-wrap: wrap;
}

.common-top {
  top: 0;
}

.common-bottom {
  bottom: 0;
}

.common-left {
  left: 0;
}

.common-right {
  right: 0;
}
.common-pointer{
  cursor: pointer;
}

.common-pading{
  padding: 0 20px;
  box-sizing: border-box;
}
.common-line:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: white;
  left: 0;
  bottom: 0;
  transform: scaleY(0.1);
}

.searchTitle{
  padding-right: 10px;
}


.disable{
  color: #B4B4B4;
}
.open{
  color: #409EFF;
}
.cell{
  cursor: pointer;
}
.cell:hover .copy{
  opacity: 1;
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
    width: 0px;
    height:10px;
}
  
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
    border-radius: 1px;
    background-color:#F0F4FC;
}
  
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
    border-radius:1px;
    background-color: #E5EBF8;
}
.el-table th.el-table__cell {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background: #E5EBF8!important;
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #4D5C72;
    height:40px;
    letter-spacing: 0;
}

.el-form-item > .el-form-item__label {
  color: #56585C !important;
}
.el-form-item > span {
  color: #13151A !important;
}
.el-message-box {
  // height: 14rem;
  border-radius: 0.75rem !important;

  .el-message-box__header {
    border-bottom: 1px solid  rgba(213, 217, 227, 0.6);
    padding:15px 10px;

  }
  .el-message-box__title{
    font-size: 16px; 
  }
  .el-message-box__content {
    margin-top:10px;
  }
  .el-message-box__btns{
    padding: 0px 25px 20px 0;
  }
}

.el-table th.el-table__cell {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background: #E5EBF8;
    border-radius:2px 2px 0 0;
    font-family: PingFangSC;
    font-size: 14px;
    color: #4D5C72;
    letter-spacing: 0;
}
.el-table__body td.el-table__cell .cell > span {
  font-size: $fontSizeMini;
  color: $fontColorTableTd;
}

/* 全局修改textarea样式*/
.el-textarea .el-textarea__inner{
    resize:none!important;
    padding-top:10px;
    padding-bottom: 10px;
}
.el-input .el-input__inner::-webkit-input-placeholder{
    color: #C0C4CC;
    font-weight: 100;
    
}
.el-textarea .el-textarea__inner::-webkit-input-placeholder{
    /* color: red; */
    font-weight: 500;
}

.el-textarea .el-input__count{
    bottom: -5px!important;
    background-color: rgba(0, 0, 0, 0);
}


.el-form-item.is-error .el-input__inner, .el-form-item.is-error .el-input__inner:focus, .el-form-item.is-error .el-textarea__inner, .el-form-item.is-error .el-textarea__inner:focus, .el-message-box__input input.invalid, .el-message-box__input input.invalid:focus {
    border-color: rgba(245, 180, 180, 0.7);
}
.el-input .el-form-item__error{
    border-color: rgba(245, 180, 180, 0.3)!important;
}
.el-popconfirm__main{
    margin-bottom: 10px!important;
}
.el-loading-spinner .el-icon-loading{
    font-size: 40px;
}


/*权限中的cass*/
#authority .cell{
    display: flex!important;
    align-items: center;
}

/* #iconImg>.el-select>.el-select-dropdown{
   
    left:0px!important;
} */
#iconImg .el-select-dropdown__list{
    padding: 0px!important;
}
#iconImg .el-select-dropdown__wrap{
    background-color: white!important;
    height: auto;
}
#iconImg .el-select-dropdown__item{
    height: auto;
    padding: 0px!important;
}

.el-form-item>.el-form-item__label:before {
  content: ' ';
  color: #F56C6C;
  margin-right: 10px;
}
.warp-GONGCHENGS{
  .el-form-item__content{
      line-height:9px!important;
  }
  .el-form-item{
      line-height:0px!important;    
  }
}
/* 自定义 Notify 样式 */
.el-notify__group {
  max-height: 300px; /* 设置最大高度 */
  overflow-y: auto; /* 显示垂直滚动条 */
  }
</style>

<template>
  <span class="copy" ref="copy">
    <el-link @click="copy" :underline="false" icon="el-icon-document-copy"></el-link>
  </span>
</template>

<script>
export default {
  props:{
    text:{
        type:String,
        default:"",
      },
    left:{
      type:Number,
      default:0,      
    },
    right:{
      right:Number,
      default:30,      
    },
    top:{
      type:Number,
      default:0,      
    },
    bottom:{
      type:Number,
      default:0,      
    },
    position:{
      type:String,
      default:'absolute'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    // 将copy元素的定位，窗口size change时出错
    init() {
      const copyElement = this.$refs.copy;
      const parentNode = copyElement.parentNode;
      const rect = copyElement.getBoundingClientRect();
      // parentNode.style.position = 'relative';
      // copyElement.style.position = this.position;
      // copyElement.style.top = '0';
      // copyElement.style.bottom = '0';
      // copyElement.style.right = '8px';
      // copyElement.style.display = 'flex';
      // copyElement.style.alignItems = 'center';
    },
    copy() {
      let data = this.text
      let url = data;
        let oInput = document.createElement('input');
        oInput.value = url;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象;
        console.log(oInput.value)
        document.execCommand("Copy"); // 执行浏览器复制命令
        this.$message({
          message: '复制成功',
          type: 'success'
        });
        oInput.remove()
    }
  }
}
</script>

<style lang="scss" scoped>
.copy{
  padding:10px 5px;
  opacity:0;
  margin-left:5px;
}
.copy:hover{
  opacity:1;
}
</style>
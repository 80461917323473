<template>
  <div class="container">
    <div class="company"><span class="txt"></span></div>
    <div class="leftDiv"></div>
    <div class="content">
        <div class="form-cont">
            <div class="topTitle common-textCenter">百仁吉物联平台</div>
            <el-form ref="loginForm" :model="loginForm" class="login-form">
                <div class="common-relatives">
                  <el-form-item prop="username">
                    <el-input v-model="loginForm.username" type="text" prefix-icon="el-icon-braccount" onkeyup='value=value.replace(/^\s*|\s*$/g,"")' auto-complete="off" placeholder="登录账号" />
                  </el-form-item>
                  <div v-if="hintUsername" class="common-absolute common-left common-bottom textCode">账号不可为空</div>
                </div>
                <div class="common-relatives">
                  <el-form-item prop="password">
                    <el-input v-model="loginForm.password" show-password type="password" auto-complete="off" onkeyup='value=value.replace(/^\s*|\s*$/g,"")' prefix-icon="el-icon-brpassword" placeholder="登录密码" />
                  </el-form-item>
                  <div v-if="hintPassword" class="common-absolute common-left common-bottom textCode">密码不可为空</div>
                </div>  
                <div class="common-relatives">
                  <el-form-item prop="code">
                      <el-input v-model="loginForm.code"  type="text" auto-complete="off" prefix-icon="el-icon-edit-group2" onkeyup='value=value.replace(/^\s*|\s*$/g,"")' placeholder="验证码" maxlength="4" style="width:100%"/>
                      <div class="codeMark">
                        <Code  @getVal="getCode"></Code>
                      </div> 
                  </el-form-item>
                  <div v-if="hintCode" class="common-absolute common-left common-bottom textCode">{{hintText}}</div>
                </div>    
                  <el-form-item style="width:100%;margin-top: 3rem;">
                    <el-button :loading="loading"  type="primary" style="width:100%;padding: 17px 20px;" @click="submitBtn">
                      <span v-if="!loading">登 录</span>
                      <span v-else>登 录 中...</span>
                    </el-button>
                  </el-form-item>
                  <div class="common-space-around warforget" >
                      <el-checkbox v-model="loginForm.rememberMe" class="remember">记住密码</el-checkbox>
                      <span class="line"></span>
                      <span class="forget" @click="forgetBtn">忘记密码</span>
                  </div>
            </el-form>
        </div>
        <ResPwd ref="respwd"/>
        <div class="QRcode" @click="handleEnter">
           <img class="common-width" src="./../assets/custom-icon/Code.svg"/>
        </div>
        <div class="common-space-around common-items-center appImg" v-if="QRcode">
             <i class="el-icon-close" @click="cosedqCCODE"></i>
             <div>
                <div>
                  <img class="Android"  src="./../assets/custom-image/Android.png"/>
                </div>
                <div class="popText">扫码下载APP</div>   
             </div>    
        </div>
    </div>
    <div class="copyright"><span @click="toUrl">版权所有©2003-2014 深圳市百仁吉科技 粤ICP备13071615号</span></div>
  </div>
</template>

<script>
import {rules,api} from '@/utils/config'
import {login} from '@/utils/httpFun'
import {hex_md5} from '@/utils/md5'
import Code from '@/components/component-block/block-ImgCode.vue' 
import ResPwd from './../components/component-block/block-resPwd.vue'
export default {
  name: 'Login',
  components:{
       Code:Code,
       ResPwd:ResPwd   
  },
  data:()=>({
      companyName: '',
      loading:false,
      hint:true, 
      hintUsername:false,
      hintPassword:false,
      hintCode:false,
      hintText:"",
      verify:"",
      QRcode:false,
      loginForm: {
          username:"",
          password:"",
          rememberMe: false,
          platform:"2",    // 1运维平台 2工厂平台
          model:'1',       // 1系统账户
          code:"",
      }
  }),
  created(){
    this.rememberPassword('start');
    document.addEventListener("keyup",this.keyup);
    // 防止pageModel一直挂起
    if (window.$instanceModalArray.length) {
      const instances = window.$instanceModalArray;
      for (let i = 0; i < instances.length; i++) {
        const instance = instances[i];

        instance.pageInstance.hide();
        app.$off('RouterChangeBefore');
      }
    }
    window.$instanceModalArray = [];
  },
  beforeDestroy(){
    document.removeEventListener("keyup",this.keyup)
  },
  methods:{
      keyup(e){if(e.code == 'Enter' || e.code=='NumpadEnter'){
        this.submitBtn();
      }},
      // 密码记住与否，有效期30天。
      rememberPassword(type = '') {
        let remember = localStorage.getItem("rememberPassword");
        const dateTime = new Date().getTime();
        const daysTime = 86400000 * 30;

        if (remember) {
          remember = JSON.parse(remember);
          const overSign = +new Date - daysTime > new Date(remember.date);

          if (remember.sign === 'YES' && type === 'start') {
            overSign
              ? (
                  this.loginForm.rememberMe = false,
                  localStorage.removeItem('rememberPassword')
                )
              : (this.loginForm.rememberMe = true, this.loginForm = remember);
          }
          if (remember.sign === 'YES' && type === 'submit') {
            !this.loginForm.rememberMe && localStorage.removeItem('rememberPassword');
            this.loginForm.rememberMe &&
              localStorage.setItem('rememberPassword', JSON.stringify({
                ...this.loginForm,
                ...{
                  date: new Date().toLocaleDateString(),
                  sign: 'YES',
                  code: ''
                }
              }));
          }
        } else {
          if (type === 'submit') {
            this.loginForm.rememberMe &&
              localStorage.setItem('rememberPassword', JSON.stringify({
                ...this.loginForm,
                ...{
                  date: new Date().toLocaleDateString(),
                  sign: 'YES',
                  code: ''
                }
              }));
            !this.loginForm.rememberMe &&
              localStorage.removeItem('rememberPassword');
          }
          if (type === 'start') {
            this.loginForm.rememberMe = false;
            localStorage.removeItem('rememberPassword');
          }
        }
      },
      //验证用户账号
      validationUseName(){
          if(!rules.NONEMPTY.test(this.loginForm.username)){
             this.hintUsername = true
             return false
          }
          this.hintUsername = false
          return true
      },
      //验证用户密码
      validationPassweord(){
          if(!rules.NONEMPTY.test(this.loginForm.password)){
             this.hintPassword = true
             return false
          }
          this.hintPassword = false
          return true
      },
      //验证验证码
      validationCode(){
        if(!rules.NONEMPTY.test(this.loginForm.code)){
             this.hintCode = true
             this.hintText = "验证码不可为空"
             return false
        }else if(this.loginForm.code+""!=this.verify){
             this.hintCode = true
             this.hintText = "验证码错误"
             return false
        }
        this.hintText = ""
        this.hintCode = false
        return true
      },

      //用户提交
      submitBtn(){
         let username = this.validationUseName()
         let password = this.validationPassweord()
         let code = this.validationCode()
         let validation = username && password && code
         if(!validation){
             return
         }
         let parms = {
             account:this.loginForm.username,
             password:hex_md5(this.loginForm.password),
             platform:'2',
             model:this.loginForm.model
         }
        
         login(api.loGin+"/auth/login",parms,res=>{
          app.username = this.loginForm.username;
          console.log('登录',this.loginForm,res)
          this.$store.commit('setUser',this.loginForm)
          this.rememberPassword('submit');
          localStorage.setItem('LeftNavBtnParms','') 
          this.$router.push({
               name: 'Index',
               query:{}
          });
         })
      },

      forgetBtn(){
        this.$refs.respwd.showWindow()
      },

      //获取验证码
      getCode(ev){
         this.verify = ev
      },
      toUrl(){
        window.location.href='https://beian.miit.gov.cn/'
      },
      //移入二维码显示下载二维码
      handleEnter(){
         this.QRcode = true
      },
      cosedqCCODE(){
        this.QRcode = false
      }
  },
  mounted() {
    this.$nextTick(() => {
      this.companyName = utils.getCompanyName();
    });
  }
}
</script>
<style lang="scss" scoped>
    .container{
          width: 100%;
          height: inherit;
          min-width: 1080px;
          min-height: 672px;
          background-color: $loginBackColor;
          background-image: $loginBackImg;
          background-size: cover;
        .content{
            position: absolute;
            top:16%;
            right: 13%;
            width: 24.2%;
            max-width: 464px;
            max-height: 572px;
            min-width: 400px;
            min-height: 490px;
            background-color: #ffffff;
            box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.07);
            border-radius:10px;
            overflow: hidden;
            
        }
        .QRcode{
           position: absolute;
           width:60px;
           height:60px;
           top:5px;
           right:5px;
           cursor: pointer;
        }
        .appImg{
            position: absolute;
            width: 100%;
            height: 100%;
            top:0px;
            left:0px;
            background-color:white;
            z-index: 1000;
        }
        .Android{
           width: 160px;
           border-radius: 8px;
        }
        .popText{
          padding-top:10px;
          text-align:center;
          font-size: 16px;
        }
        .el-icon-close{
          position: absolute;
          top: 20px;
          right:20px;
          font-size:20px;
          font-weight: 600;
          cursor: pointer;
        }
    }

    .company {
      height:60px;
      display: flex;
      align-items: center;
      // background-color: $fontColorWhite;
      opacity: 0;
      .txt {
        display: inline-block;
        margin-left:32px;
        color: #4D5C72;
        font-size: $fontSizeMedium;
        font-weight: bold;
      }
    }

    .leftDiv{
        position: absolute;
        top: 16%;
        left: 23%;
        width: 25%;
        height: 48%;
        background-image: $loginLefBackImg;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 1rem 0 0 1rem;
    }
    .form-cont{
        flex: 1;
        padding: 5rem 3.2rem  6rem 3.2rem;
        box-sizing: border-box;
        // background-color: yellow;
        border-radius:10px;
        position: relative;
        .topTitle{
          padding-bottom: 3.25rem;
          font-family: PingFangSC-Semibold;
          font-size: 26px;
          color: #2790FF;
          font-weight: 600;
        }

        .textCode{
          font-size: 0.75rem;
          bottom: -1.125rem;
          color: #e6a23c;
        }
        .el-form-item{
           margin-bottom: 1.74rem;
        }
        .codeMark{
          position: absolute;
          height: 50px;
          overflow: hidden;
          top: 0;
          right: 0;
          border-radius: 0 6px 6px 0;
        }
        .line {
          display: inline-block;
          width: 1px;
          margin: 0 1.5625rem;
          background-color: #DFE1E6;
        }
        .forget{
          font-size: 14px;
          color: $fontColorCopyright;
          cursor:pointer;
        }
        .remember {
          color: $fontColorCopyright;
          font-size: 14px;
        }
        .space-between {
          margin-top: 35px;
          height: 19px;
          display: flex;
          justify-content: center;
        }
        
    }
    .copyright {
      position: absolute;
      left: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1.875rem;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: $fontSizeMini;
        color: $fontColorCopyright;
        padding-bottom: 20px;
        cursor: pointer;
      }
    }
    .warforget{
      padding: 0 4rem;
      box-sizing: border-box;
      margin-top: 3rem;
    }
    ::v-deep .el-button--medium {
      border-radius: 10px;
      background: linear-gradient(to right, #087FFC, #44B3FF);
    }
    ::v-deep .checked-code {
      .el-input__inner {
        border: none;
      }
    }
    ::v-deep.el-input .el-input__inner {
      border-radius:6px;
      height: 50px;
    }
</style>

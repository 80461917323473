<template>
    <el-dialog  title="忘记密码" :visible.sync="showBlock" :destroy-on-close="true" :close-on-click-modal="false" width="450px" :before-close="closedBtn">
        <el-form :model="formDate"  ref="formDate"  label-width="80px" class="demo-ruleForm">
            <el-form-item  label="手机号">
                <div class="common-relatives">
                    <input v-model="formDate.phone" maxlength="11" class="minput"  onkeyup='value=value.replace(/^\s*|\s*$/g,"")'   placeholder="请输入手机号码" autocomplete="off" />
                    <div class="common-absolute pop">{{phonetext}}</div>
                </div>
            </el-form-item>
            <div class="common-items-center ">
                <el-form-item  label="验证码" >
                    <div class="common-relatives">
                        <input v-model="formDate.code" maxlength="4" class="minput common-flexw"  onkeyup='value=value.replace(/^\s*|\s*$/g,"")' placeholder="请输入验证码" autocomplete="off"   />
                        <div class="common-absolute pop">{{codetext}}</div>
                    </div>
                </el-form-item>
                <div class="codeClass" v-if="showCodeBtn" @click="sendCode">发送验证码</div>
                <div class="codeClass active" v-else >{{ms}}S后重新发送</div>
            </div>
            <el-form-item  label="新密码" >
                <div class="common-relatives">
                     <input v-model="formDate.newPwd" maxlength="12" class="minput"  type="password" onkeyup='value=value.replace(/^\s*|\s*$/g,"")'  placeholder="请输入新密码" autocomplete="off" />
                     <div class="common-absolute pop">{{newPwdtext}}</div>
                </div>
            </el-form-item>
            <el-form-item  label="确认密码" >
                <div class="common-relatives">
                    <input v-model="formDate.rewPwd" maxlength="12" class="minput" type="password"  onkeyup='value=value.replace(/^\s*|\s*$/g,"")'   placeholder="再次输入新密码" autocomplete="off" />
                    <div class="common-absolute pop">{{rewPwdtext}}</div>
                </div>
            </el-form-item>
            <div class="warp-btn">
                <el-form-item>
                    <div  class="dialog-footer">
                        <button class="clear" @click="closedBtn">取消</button>
                        <button class="sure" type="primary"  v-if="showBtn">
                            <i class="el-icon-loading"></i>提交
                        </button>
                        <button class="sure" type="primary" v-else @click="submitBtn">提交</button>
                    </div>
                </el-form-item>
            </div>
        </el-form>
    </el-dialog>
</template>
<script>
    import axios from 'axios'
    import {api} from '@/utils/config'
    import {rules} from '@/utils/config'
    import {hex_md5} from '@/utils/md5'
    import { getFun, putFun } from '@/utils/httpFun'
    export default{
        data(){
           return{
               ms:60,
			   showCodeBtn:true,
               showBlock:false,
               showBtn:false,
               phonetext:null,
               codetext:null,
               newPwdtext:null,
               rewPwdtext:null,
               formDate:{
                  phone:null,
                  code:null,
                  newPwd:null,
                  rewPwd:null
               }
            }
        },
        created(){
 
        },
        mounted(){
        },
        methods:{
            
            //关闭
            closedBtn(){
               this.showBlock = false
               this.formDate.phone=null
               this.formDate.code=null
               this.formDate.newPwd=null
               this.formDate.rewPwd=null
               this.phonetext=null
               this.codetext=null
               this.newPwdtext=null
               this.rewPwdtext=null
               this.ms = 0
            },

            //开启弹窗
            showWindow(){
                this.showBlock = true
                this.formDate.phone=null
                this.formDate.code=null
                this.formDate.newPwd=null
                this.formDate.rewPwd=null
                this.phonetext=null
                this.codetext=null
                this.newPwdtext=null
                this.rewPwdtext=null
            },
            //60毫秒倒计时
			countdown(){	
			  if (this.ms == 0) {
				this.ms = 60;
				this.showCodeBtn = true
			  } else {
				this.ms -- ;
				this.showCodeBtn = false
				setTimeout(res=>{
				  this.countdown()
				}, 1000)
			  }
			},

            
            //检验手机号是否为空
            checkPhone(){
                if(!this.formDate.phone){
                   this.phonetext = "手机号不可为空"
                   return false
                }else if(!rules.PHONE.test(this.formDate.phone)){
                    this.phonetext = "手机号格式不正确"
                    return false
                }else{
                    this.phonetext = ""
                    return true
                }
            },

            //检验验证码是否为空
            checkCode(){
                if(!this.formDate.code){
                   this.codetext = "验证码不可为空"
                   return false
                }else{
                   this.codetext = ""
                   return true
                }
            },
            
            //检验验证码是否为空
            checkPwd(){
                if(!this.formDate.newPwd){
                   this.newPwdtext = "新密码不可为空"
                   return false
                }else{
                   this.newPwdtext = ""
                   return true
                }
            },

              


            //检验验证码是否为空
            checkREPwd(){
                if(!this.formDate.rewPwd){
                   this.rewPwdtext = "确认密码不可为空"
                   return false
                }else if(!(this.formDate.newPwd==this.formDate.rewPwd)){
                   this.rewPwdtext = "两次密码不一致"
                   return false
                } else{
                   this.rewPwdtext = ""
                   return true
                }
            },
     
            sendCode(){
                if(!this.checkPhone()){
                   return
                }

                axios.get('/engineer-applet-service/common/sendCode?phone='+this.formDate.phone).then(res=>{
                    if(!!res.data.data){
                        this.countdown()
                    }else{
                        this.$mmessage.warning('网络繁忙'); 
                    }
                }).catch(function (err) {
                    this.$mmessage.warning('网络繁忙'); 
                });  
            }, 
            //提交
            submitBtn(){
               let checkPhone = this.checkPhone()
               let checkCode = this.checkCode()
               let checkPwd = this.checkPwd()
               let checkREPwd = this.checkREPwd()
               let mark = checkPhone && checkCode && checkPwd && checkREPwd


               
               if(mark){
                   let parm = {
                       phone:this.formDate.phone,
                       code:this.formDate.code,
                       newPwd:hex_md5(this.formDate.newPwd)
                   }
                   if(!rules.ENGCHENHSH.test(this.formDate.newPwd)){
                        this.rewPwdtext = "密码须由6~12位字母数字组混合而成位"
                        return  
                    }
                    this.rewPwdtext = ""
                   putFun(api.other+"/account/resetPwdByCode",parm,res=>{
                      if(res.data){
                          this.closedBtn()
                      }else{
                        this.$message.warning(res.data.exceptionMsg);  
                      }
                   })
               }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .minput{
        height:40px;
        border: 1px solid rgb(213, 217, 227) !important;
        border-radius: 4px;
        outline: none;
        padding: 0 10px;
        box-sizing: border-box;
        width:97%;
    }
    .pop{
        top:29px;
        left:0;
        color: #F56C6C;
        font-size:12px;
    }
    button{
        height: 36px;
        margin-left: 10px;
        padding: 0 20px;
        border:none;
        font-size: 14px;
        border-radius: 4px;
    }
    .clear{
        background-color: white;
        border:1px solid rgb(213, 217, 227);
        color:rgba(0,0,0,0.6)
    }
    .codeClass{
        height: 40px;
        padding: 0 10px;
        border:1px solid rgb(213, 217, 227);
        color:rgba(0,0,0,0.6);
        border-radius: 4px;
        font-size: 14px;
        line-height: 40px;
        width:88px;
        text-align:center;
        position: relative;
        bottom: 10px;
        left: 10px;
    }
    .codeClass.active{
        font-size:12px;
        background-color:#E1E1E1;
    }
    .sure{
        background-color:#458EFF;
        color: white;
    }

    .dialog-footer{
       display: flex;
       justify-content: flex-end;
   }
   ::v-deep .el-input-number .el-input__inner{
        text-align: left!important;
    } 
    
   .warp-btn{
    margin-top: 20px;
    .el-form-item {
        margin-bottom:0px!important;
     }
   }
</style>
<template>
      <div>
        <el-upload
              ref="upload"
              :action="url"
              list-type="picture-card"
              :limit="limit*1"
              :headers="headers"
              :on-preview="showBtn"
              :before-upload="UploadLimitBtn"
              :on-remove="handleRemove"
              :on-success="uploadImg" 
              :file-list="showImgList"
              :disabled="disabled"
              >
              <i class="el-icon-plus"></i>
          </el-upload>
          
          <el-dialog :visible.sync="dialogVisible" :modal="false">
              <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
          <div class="reminder" v-if="width*1!=0">建议图片尺寸{{width}}*{{height}}</div>
        </div>
</template>
<script>
  import { url,api,urlAddress } from '@/utils/config';
  export default {
    data:()=>({
        url:urlAddress.UPLOADINGMO,
        headers:{'Authorization':localStorage.getItem("brjYunWeiFactoryTiken")},   //请求头部
        uploadVal:[],
        showImgList:[],
        dialogImageUrl: '',
        dialogVisible: false
    }),
    props:{
        limit:{
          type: String,
          default: "1",  
        },
        keyVal:{
          type: String,
          default: "",
        },
        width:{
           type: String,
           default: "0",
        },
        height:{
           type: String,
           default: "0",
        },
        size:{
           type: String,
           default: "5",
        },
        disabled: {
          type: Boolean,
          default: () => false
        }
    },
    mounted() {
      this.$nextTick(() => {
        if (this.disabled) {
          this.$refs.upload.$el.lastChild.style.display = "none";
        }
      });
    },
    methods:{ 

      UploadLimitBtn(file){
        let isLt2M = file.size / 1024 / 1024 < this.size*1;
        if (!isLt2M) {
            this.$message.warning('上传图片大小不能超过 '+this.size+"MB");
            return  isLt2M;
        }
      }, 

      //上传图片
      uploadImg(response, file, fileList){
        this.uploadVal = [...this.uploadVal,...response.data]
        let parms = {
               key:this.keyVal,
               val:this.uploadVal
        }
        this.$emit('getUpload',parms)
      },

      //删除图片
      handleRemove(file, fileList){
        if(!file.response){
            let url = file.url;
            let valList = this.uploadVal;
            for(var i in valList){
               if(url.indexOf(valList[i])!=-1){
                  this.uploadVal.splice(i,1);
                  break;
               }
            }
         }else{
            let ImgName = file.response.data[0];
            this.uploadVal.splice(this.uploadVal.indexOf(ImgName),1);
         }
        let parms = {
            key:this.keyVal,
            val:this.uploadVal
        };
        this.$emit('getUpload',parms);
      },
      //设置数据
      setList(params) {
        let listImg = params.showImg
        let newList = []
            for(var i in listImg){
              newList.push({url:listImg[i]})
            }
        this.uploadVal = params.uploadVal
        this.showImgList = newList
      },
      //清空数据
      clearData() {
        this.uploadVal= []
        this.showImgList = []
      },
      showBtn(file) {
          this.dialogImageUrl = file.url;
          this.dialogVisible = true;
      }
    }
  }
</script>
<style>
   .reminder{
      color: red;
      padding-top:10px;
      padding-bottom:10px;
   }
</style>
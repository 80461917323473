
const configList = {
  report: [
    { title: "设备", val: 1 },
    { title: "电路", val: 2 },
    { title: "人员", val: 3 },
    { title: "场地", val: 4 },
    { title: "其他", val: 5 }
  ],
  listImg: [
    { url: require("@/assets/custom-image/project/inconFire.png"), val: 1 },
    { url: require("@/assets/custom-image/project/incon-newEn.png"), val: 2 }
  ],
  polictList: [
    { title: "忽略", val: 1 },
    { title: "误报", val: 2 },
    { title: "演练", val: 3 },
    { title: "报警", val: 4 },
    { title: "其他", val: 5 },
  ],
  timeData: [
    { indexNo: 1, selectName: "秒", val: '1' },
    { indexNo: 2, selectName: "分", val: '60' },
    { indexNo: 3, selectName: "时", val: '3600' },
    { indexNo: 4, selectName: "天", val: '86400' },
    { indexNo: 5, selectName: "周", val: '604800' }
  ],
  statusList: [
    { title: "未处理", val: 0 },
    { title: "已处理", val: 1 },

  ],
  selectBtn: [
    { title: "近一周", val: 0 },
    { title: "近一月", val: 1 },
    { title: "近一年", val: 2 },
  ],
  industrList: [
    { indexNo: 1, selectName: '=' },
    { indexNo: 2, selectName: '>' },
    { indexNo: 3, selectName: '<' },
    { indexNo: 4, selectName: '>=' },
    { indexNo: 5, selectName: '<=' }
  ],
  enableList: [
    { title: "启用", val: 1, color: "#07C160" },
    { title: "禁用", val: 0, color: "#B4B4B4" }
  ],
  receiveList: [
    { title: "语音", val: 1 },
    { title: "短信", val: 2 },
    { title: "公众号", val: 3 },
    { title: "系统", val: 4 },
  ],
  merchantList: [
    { title: '代理商', val: 3 },
    { title: '工程商', val: 4 }
  ],
  clientList: [
    { title: "PC", val: 1, color: "#409EFF" },
    { title: "APP", val: 2, color: "#3AE2CE" },
    { title: "小程序", val: 3, color: "#07C160" }
  ],
  modelList: [
    {
      code: "gmt",  //门磁
      list: [
        { title: "csq", val: "信号强度" },
        // { title: "signalNumber", val: "信号强度" },
        { title:"iccid", val:"ICCID"},
        { title:"deviceTime", val:"设备时间"},
        { title: "imsi", val: "IMSI" },
        { title: "voltage", val: "电压", unit: "V" },
        { title: "temperature", val: "环境温度", unit: "°C" },
        { title: "reportTime", val: "上报时间" },
        { title: "status", val: "状态", },  //0关门 1开门
      ]
    },
    {
      code: "dgat",  //家用燃气
      list: [
        { title: "csq", val: "信号强度" },
        { title:"iccid", val:"ICCID"},
        { title:"deviceTime", val:"设备时间"},
        { title: "imsi", val: "IMSI" },
        // { title: "signalNumber", val: "信号强度" },
        { title: "electricityQuantity;", val: "电量", unit: "%" },
        { title: "temperature", val: "环境温度", unit: "°C" },
        { title: "reportTime", val: "上报时间" },
        { title: "policeStatus;", val: "报警状态" },   //true报警 false正常
      ]
    },
    {
      code: "lgat",  //工业燃气
      list: [
        { title: "csq", val: "信号强度" },
        { title:"iccid", val:"ICCID"},
        { title:"deviceTime", val:"设备时间"},
        // { title: "signalNumber", val: "信号强度" },
        { title: "concentration", val: "浓度", unit: "%" },
        { title: "imsi", val: "IMSI" },
        { title: "reportTime", val: "上报时间" },
        { title: "privateModel", val: "私有物模型" },
      ]
    },
    {
      code: "gahft",  //4G工业主机
      list: [
        { title: "csq", val: "信号强度" },
        { title:"iccid", val:"ICCID"},
        { title:"deviceTime", val:"设备时间"},
        { title: "imsi", val: "IMSI" },
        // { title: "signalNumber", val: "信号强度" },
        { title: "policeStatus", val: "报警状态" },   //true报警 false正常
        { title: "concentration", val: "浓度", unit: "%" },
        { title: "slaveId", val: "设备ID" },
        { title: "switchStatus", val: "开关状态", },  //true代表闭合，false代表断开
        { title: "reportTime", val: "上报时间" },
      ]
    },
    {
      code: "sscdt", //烟感复合探测器物模型
      list: [
        { title: "csq", val: "信号强度" },
        { title:"iccid", val:"ICCID"},
        { title:"deviceTime", val:"设备时间"},
        { title: "imsi", val: "IMSI" },
        // { title: "signalNumber", val: "信号强度", unit: "V" },
        { title: "voltage", val: "电压", unit: "V" },
        { title: "smokePolice", val: "烟感报警" },
        { title: "tempPolice", val: "温度报警" },
        { title: "reportTime", val: "上报时间" },
        { title: "privateModel", val: "私有物模型" },
      ]
    },
    {
      code: "sdt", //烟感物模型
      list: [
        { title: "csq", val: "信号强度" },
        { title:"iccid", val:"ICCID"},
        { title:"deviceTime", val:"设备时间"},
        // { title: "signalNumber", val: "信号强度" },
        { title: "voltage", val: "电压", unit: "V" },
        { title: "policeStatus;", val: "报警状态" },     //true报警 false正常
        { title: "imsi", val: "IMSI" },
        { title: "reportTime", val: "上报时间" },
        { title: "privateModel", val: "私有物模型" },
      ]
    },
  ]
}
module.exports = {
  conText: configList
}
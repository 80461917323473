<template>
	<!--pubTable-new 将会替代当前文件-->
	<div>
		<div class="search">
			<slot name="search"></slot>
		</div>
		<el-table :data="tableData" :stripe="stripe" :border="border" row-key="id" :size="size" :fit="fit"
			:showHeader="showHeader" :tree-props="childrenPorps" :highlightCurrentRow="highlightCurrentRow"
			:height="THeight" @selection-change="selectionChange">
			<el-table-column v-for="(tItem, tIndex) in typesColumns" :key="tIndex" :type="tItem.type" :fixed="tItem.fixed"
				:align="tItem.align" :header-align="tItem.headerAlign" :width="tItem.width" :minWidth="tItem.minWidth"
				:label="tItem.label" />
			<el-table-column v-for="item in tableColumns" :prop="item.prop" :key="item.prop" :label="item.label"
				:width="item.width" :minWidth="item.minWidth" :align="item.align" :header-align="item.headerAlign"
				:fixed="item.fixed" :show-overflow-tooltip="item.tooltip || false">
				<template slot-scope="scope">
					<custom v-if="item.render" :render="item.render" :row="scope.row" :index="scope.$index"></custom>
					<span v-else>{{ scope.row[item.prop] }}</span>
				</template>
			</el-table-column>
		</el-table>
		<Paging v-if="action && !isLoading" @getPage="getPage" :total="page.total" :pageNo="page.current"
			:size="page.size" />
		<Paging v-if="isLocationPaging && !isLoading" @getPage="getPage" :total="page.total" :pageNo="page.current"
			:size="page.size" />
		<loading v-if="isLoading" @load-data="loadData" :outloading="dataloading" :finish="finish"></loading>
	</div>
</template>

<script>
import { postFun } from '@/utils/httpFun';
import custom from './custom.vue';
import Loading from '../loading.vue';
import store from '@/utils/store';
export default {
	name: 'PubTable',
	data: () => ({
		THeight: 0
	}),
	props: {
		callback: Function,
		action: {
			type: String,
			default: () => ''
		},
		tableHeight: {
			type: Number,
			default: (value) => {
				// debugger;
				if(!!!value){
					console.log('表格230')
					return 230;
				}else{
					console.log('表格',app.$store.state.windowHeight)
					return app.$store.state.windowHeight - value;
				}
			}
		},
		childrenPorps: {
			type: Object,
			default: () => ({
				children: 'productGetVo',
				hasChildren: 'hasChildren'
			})
		},
		params: {
			type: Object,
			default: () => ({})
		},
		data: {
			type: Array,
			default: () => []
		},
		columns: {
			type: Array,
			default: () => []
		},
		stripe: {
			type: Boolean,
			default: () => false
		},
		border: {
			type: Boolean,
			default: () => false
		},
		size: {
			type: String,
			default: () => 'small'
		},
		fit: {
			type: Boolean,
			default: () => true
		},
		showHeader: {
			type: Boolean,
			default: () => true
		},
		highlightCurrentRow: {
			type: Boolean,
			default: () => true
		},
		isLocationPaging: {
			type: Boolean,
			default: () => false
		},
		isLoading: {
			type: Boolean,
			default: () => false
		}
	},
	components: {
		custom,
		Loading,
	},
	computed: {
		refreshStr() {
			return this.$store.state.windowHeight;
		}
	},
	watch: {
		refreshStr(val) {
			if (val) {
				console.log('监听')
				this.THeight = this.$store.state.windowHeight - this.tableHeight
			}
		}
	},
	created() {
		this.THeight = app.$store.state.windowHeight - this.tableHeight
		this.splitColumns();
		if (!this.action) {
			this.tableData = this.data;
		} else {
			this.tableParams.param = { ...this.params };
			if (!this.isLoading)
				this.getList(this.action, this.tableParams);
			else {
				let tableParamsCopy = JSON.parse(JSON.stringify(this.tableParams))
				tableParamsCopy.size = 20;
				this.getListCopy(this.action, tableParamsCopy)
			}
		}
	},
	watch: {
		data: {
			handler() {
				this.locationData = this.data;
				if (this.isLocationPaging) {
					this.page.total = this.locationData.length;
					if (this.data.length === 0) {
						this.tableData = this.data;
					} else {
						this.locationGetList();
					}
				} else {
					this.tableData = this.data;
				}
			},
			deep: true,
		}
	},
	data() {
		return {
			dataloading: false,
			tableData: [],
			typesColumns: [],
			tableColumns: [],
			tableParams: {
				param: {},
				current: 1,
				size: 20
			},
			page: {
				total: 0,
				current: 1,
				size: 20
			},
			finish: 'NO',
			locationData: [],
		}
	},
	mounted() {
		// this.tHeight = this.$store.state.windowHeight-this.tableHeight
	},
	methods: {
		splitColumns() {
			const typesColumns = [];
			const columns = [];

			for (const column of this.columns) {
				if (column.type) {
					typesColumns.push(column)
				} else {
					columns.push(column);
				}
			}

			this.typesColumns = typesColumns;
			this.tableColumns = columns;
		},
		getTableData() {
			return this.tableData
		},
		search(params) {
			this.tableParams.current = 1;
			if (params) {
				this.tableParams.param = { ...params };
			} else {
				this.tableParams.param = { ...this.params };
			}
			this.finish = 'NO';
			this.tableData = [];
			this.getList(this.action, this.tableParams);
		},
		locationGetList() {
			if (this.page.current > Math.ceil(this.page.total / this.page.size) || this.page.current < 0) return;
			const splitNum = this.page.size * (this.page.current - 1);
			const data = this.locationData.slice(splitNum, splitNum + this.page.size);
			this.tableData = data;
		},
		getList(url, params) {
			postFun(url, params, res => {
				this.dataloading = false;
				const list = !!this.callback ? this.callback(res.data && res.data.records || []) : (res.data && res.data.records || []);
				this.tableData = list
				this.page.total = res.data && res.data.total || 0;
				this.page.size = res.data && res.data.size || 10;
				this.page.current = res.data && res.data.current || 1;
				store.commit({
					type: "setLength",
					tableDataLength: res.data && (res.data.total + 1)       //便于设备默认排序
				})
				if (res.data && (this.tableData.length === res.data.total)) this.finish = 'YES';
			}).catch(() => {
				this.dataloading = false;
				this.tableParams.current--;
			});
		},
		getPage(page) {
			this.tableParams.current = page.pageNo;
			this.tableParams.size = page.pageSize;
			this.page.current = page.pageNo;
			this.page.size = page.pageSize;
			!this.isLocationPaging && this.getList(this.action, this.tableParams);
			this.isLocationPaging && this.locationGetList();
		},
		selectionChange(val) {
			this.$emit('selection-change', val);
		},
		loadData() {
			this.dataloading = true;
			this.tableParams.current++;
			let tableCopy = JSON.parse(JSON.stringify(this.tableParams));
			tableCopy.size = 20;
			this.getListCopy(this.action, tableCopy);
		},
		getListCopy(url, params) {
			postFun(url, params, res => {
				this.dataloading = false;
				const list = !!this.callback ? this.callback(res.data && res.data.records || []) : (res.data && res.data.records || []);
				this.tableData = this.tableData.concat(list);
				store.commit({
					type: "setLength",
					tableDataLength: res.data && (res.data.total + 1)       //便于设备默认排序
				})
				if (res.data && (this.tableData.length === res.data.total)) this.finish = 'YES';
			}).catch(() => {
				this.dataloading = false;
				this.tableParams.current--;
			});
		},
	}
}
</script>

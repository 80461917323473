<template>
  <!--新的左侧导航菜单为完成-->
  <div class="menu" :style='{width:this.$store.state.leftBlockWidth+"px" }'>
    <div  v-for="(item,index) in lists">
      <div v-if="!!item.children">
        <!--父块-->
        <div class="common-items-center common-relatives btn" :class='[item.select ? "active":""]' @click="fatherBtn(index,item)">
            <div class="imgBlockSvg">
                 <img :src='"@/assets/custom-menuIcon/"+item.image+".svg"' :class='[item.select ? "iconctive":"icon"]'/>
            </div>
            <div class="text">{{item.name}}</div>
            <i class="el-icon-arrow-down" v-if="item.mark" @click.stop="openDown(index,item)"></i>
            <i class="el-icon-arrow-right" v-else @click.stop="openDown(index,item)"></i>
            <div class="common-absolute mask" v-if="item.select" @click.stop="openDown(index,item)">mask</div>
        </div>
        <!--子块-->
        <div class="child" v-if="item.mark">
            <div class="common-items-center block" v-for="(childItem, childIndex) in item.children" @click="childBtn(index,item,childIndex,childItem)">
              <div class="imgBlockSvg">
                    <img :src='"@/assets/custom-menuIcon/"+childItem.image+".svg"' :class='[childItem.id == childId ? "iconctivez":"iconz"]' />
              </div>
              <div class="text" :class='[childItem.id == childId ? "active":""]'>{{childItem.name}}</div>
            </div>
        </div> 
      </div>
      <!--没有子块-->
      <div v-else class="common-items-center common-relatives btn" :class='[item.select ? "active":""]' @click="fatherBtn(index,item)">
            <div class="imgBlockSvg">
                <img :src='"@/assets/custom-menuIcon/"+item.image+".svg"'  :class='[item.select ? "iconctive":"icon"]' />
            </div>
            <div class="text">{{item.name}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { url, api } from '../utils/config';
import { getFun } from '../utils/httpFun';


export default {
  name: 'BlockMenu',
  data() {
    return {
      childId: 0,
      lists: [],
      activeIds: [],
      webUrl:''
    }
  },

  mounted(){
    this.getLIst();
    this.$bus.$off('menuSkip');
    this.$bus.$on('menuSkip',res=>{
      this.skip(res);
    })
  },

  methods: {
    //获取左侧的菜单栏
    getLIst() {
        getFun(api.other + "/personalCenter/getMenuButtonByUserId", {}, res => {
          let list =  res.data
          // if(!!!list)return;
          // console.log('==============store',this.$store, res.data)
          // this.$store.commit('setMenuList',{menuList:[...list]})
          console.log('=listlistlist=====list',list)
              //过滤掉超级菜单
              try{
                list = list.filter(item=>item.type != 3)
              }catch{}
              //为每个菜单添加打开的标志
              for(var i in list){
                  if(i == 0){
                    list[i].mark = true
                    list[i].select = true
                  }else{
                    list[i].mark = false
                    list[i].select = false
                  }
              }
            this.lists = list;

            //有本地缓存
            let selectedParms = localStorage.getItem("LeftNavBtnParms")
            // console.log(!!selectedParms)  
            if(!!selectedParms){
              let str = selectedParms;
              let obj = JSON.parse(str);
            
              if(obj.webUrl == 'Screen'){
                // 本地大屏
                this.$router.replace({
                    name:'Screen',
                    params:obj.params
                })
                return;
              }  

              this.entUrl( JSON.parse(selectedParms))
              this.childId = JSON.parse(selectedParms).id
              for(var i in list){
                  if(JSON.parse(selectedParms).parentId==0){
                     if(list[i].id == JSON.parse(selectedParms).id){
                        list[i].mark = true
                        list[i].select = true
                     }else{
                        list[i].mark = false
                        list[i].select = false
                     }
                  }else{
                    if(list[i].id == JSON.parse(selectedParms).parentId){
                      list[i].mark = true
                      list[i].select = true
                    }else{
                      list[i].mark = false
                      list[i].select = false
                    }
                  }
              }
              this.$nextTick(res=>{
                 this.lists = list;
              })
              return
            }

            //本地缓存不存在
            if(!!this.lists[0].children){
              this.$set(this.lists[0],'mark',true)
              this.$set(this.lists[0],'select',true)
              this.childId = this.lists[0].children[0].id
              this.entUrl(this.lists[0].children[0])
            }else{
              this.entUrl(this.lists[0])
            }


            console.log('===============菜单',this.lists);
            window.localStorage.setItem('factyRouteList',JSON.stringify(this.lists))

        }).catch(error => {
            console.log('获取菜单出错啦');
        });
    },

    //展开与关闭
    openDown(index,ev){
       this.$set(this.lists[index],'mark',!ev.mark)
    },

    // 跳转指定位置
    skip(item){
      console.log('=============跳转',this.lists)
    },
    //点击父类块
    fatherBtn(index,ev){
       let list = this.lists
       for(var i in list){
          if(i == index){
            list[i].select = true
            list[i].mark = true 
          }else{
            list[i].select = false
            list[i].mark = false
          }
       }
       if(!!ev.children){
         this.entUrl(ev.children[0])
         this.childId = ev.children[0].id
       }else{
         this.entUrl(ev)
       }
       this.lists = list
    },

    //点击子块
    childBtn(index,item,childIndex,childItem){
       let list = this.lists
       for(var i in list){
          if(i == index){
            list[i].select = true
            list[i].mark = true 
          }else{
            list[i].select = false
            list[i].mark = false
          }
       }
       this.entUrl(childItem)
       this.childId = childItem.id
       this.lists = list
    },

    //跳转到对应的URL
    entUrl(item){
        //防止重复点击
        if(item.webUrl==this.webUrl){
           return
        }
        localStorage.setItem("LeftNavBtnParms",JSON.stringify(item))
        this.webUrl = item.webUrl
        this.$router.replace({
          path: item.webUrl,
          name: item.webUrl,
          query: {}
        });
    }
  }
}
</script>

<style lang="scss" scoped>
   .menu{
      height:100%;
      padding:8px 8px  50px 8px;
      box-sizing: border-box;
      position: relative;
      z-index:10;
      overflow-y: auto;
      -moz-user-select:none; /* Firefox私有属性 */
      -webkit-user-select:none; /* WebKit内核私有属性 */
      -ms-user-select:none; /* IE私有属性(IE10及以后) */
      -khtml-user-select:none; /* KHTML内核私有属性 */
      -o-user-select:none; /* Opera私有属性 */
      user-select:none; /* CSS3属性 */
      box-shadow: 4px 4px 15px 0px rgba(88,136,210,0.14);
      background-image: url('@/assets/custom-icon/sidemenu.svg');
   }
   .btn{
      height:36px;
      width:100%;
      line-height: 36px;
      padding: 0 7px;
      box-sizing: border-box;
      background: #fff;
      box-shadow: 4px 5px 15px 0 rgb(205 216 233 / 39%);
      border-radius: 6px;
      position: relative;
      cursor: pointer;
      margin-bottom: 9px;
   }
   .btn.active{
    background-image: linear-gradient(270deg, #4AA7FF 0%, #246EFF 100%);
    box-shadow: 2px 2px 12px 0px rgba(66, 155, 255, 0.55);
     .text{
        color: white;
     }
     .el-icon-arrow-right,.el-icon-arrow-down{
        color: white;
     }
   }
   .mask{
     width: 100%;
     height: 100%;
     top: 0;
     left: 0;
     background-color: red;
     opacity:0;
   }
   .child{
      margin-top: 16px;
      padding: 0 10px 0 10px;
      box-sizing: border-box;
      cursor: pointer;
      .block{
         margin-bottom: 9px;
      }
      .text.active{
         color:#246EFF;
         font-size: 14px;
         font-weight: 700;
      }
   }
   .imgBlockSvg{
      height: 30px;
      width: 30px;
      overflow: hidden;
      position: relative;
   }
   .text{
      font-size: 14px;
      color: #4d5c72;
   }
   .icon{
      position: absolute;
      top:-3px;
      right:0px;
   }
   .iconctive{
      position: absolute;
      top:-2px;
      right:-36px;
   }
   .iconz{
      position: absolute;
      top:-2px;
      right:0px;
   }
   .iconctivez{
      position: absolute;
      top:-3px;
      right:-36px;
   }


   .el-icon-arrow-right{
      padding: 5px;
      position: absolute;
      right:5px;
      top:8px;
   }
   .el-icon-arrow-down{
      padding: 5px;
      position: absolute;
      right:5px;
      top:10px;
   }
</style>

import utils from './utils'
// 大屏store
let screenStore = {
  state:{
    // 高德和中国地图切换 true 中国地图 false 高德地图
    screenIsMap:true, 
    // 当前处于 0 系统概况 1 设备分布 2 客户分布
    screenCurrentCircumstances:0,
    // 卫星图切换 0 卫星图 1 2d图
    screenIsCoverage:'1',
    // 项目详情打开关闭 1 打开 0 关闭
    screenProjectDeail:'0',
    // 打开地图的项目详情弹框
    screenProjectWindowDeail:'0',
    // 高德地图的marker
    screenAutoNaviMarker:[],
    // 中国地图的marker
    screenchinaMarker:[],
    // 每次请求的打点数据
    screenProjectMarker:[],
    // 实时报警的数组
    screenRealTimePoliceList:[],
    // 地图层级 1 省 2 市 3 区
    screenMapLevel:'1',
    // 报警状态 0 已报警 1 未报警 2 所有设备
    screenPoliceType:'2',
    // 项目详情 弹框的项目id
    screenProjectId:'',
    // 设备分类
    screenDeviceTypeList:[],
    // 全局省code
    screenProvinceCode:'',
    // 设备分布地图地图显示 true 中国地图 false 高德地图
    screenDistributionMap:false,
    // 设备分布点击代理商获取id
    screenDistributionAgentId:null,
    // 设备分布点击工厂获取id
    screenDistributionEngId:null,
    // 是否处于高德点位加载状态
    screenIsLoading:true,

  },
  mutations:{
    clearScreenVuex(state,val){

      // window.localStorage.setItem('whichScreen','newScreen');

      state.screenIsMap = true;
      state.screenCurrentCircumstances = 0;
      state.screenIsCoverage = '1';
      state.screenProjectDeail = '0';
      state.screenProjectWindowDeail = '0';
      state.screenAutoNaviMarker = [];
      state.screenchinaMarker = [];
      state.screenProjectMarker = [];
      state.screenRealTimePoliceList = [];
      state.screenMapLevel = '1';
      state.screenPoliceType = '2';
      state.screenProjectId = '';
      state.screenDeviceTypeList = [];
      state.screenProvinceCode = '';
      state.screenDistributionMap = false;
      state.screenIsLoading = true;
      state.screenDistributionEngId = null;
      state.screenDistributionAgentId = null;
    },
    // 修改切换
    setIsMap(state,val){
      if(!val && state.screenIsLoading){
        utils.prompt('高德地图未加载完请稍后再试');
        return;
      }
      state.screenIsMap = val;
    },
    // 修改当前环境
    setCurrentCircumstances(state,val){
      state.screenCurrentCircumstances = val;
    },
    // 修改图层
    setIsCoverage(state,val){
      state.screenIsCoverage = val;
    },
    // 修改项目详情
    setProjectDeail(state,val){
      state.screenProjectDeail = val;
    },
    // 设置高德打点
    setAutoNaviMarker(state,val){
      state.screenAutoNaviMarker = val;
    },
    // 设置中国地图的打点
    setChinaMarker(state, val) {
      state.screenchinaMarker = val;
    },
    // 请求 打点处理后的的数据
    setProjectMarker(state,val){
      state.screenProjectMarker = val;
    },
    // 实时报警的数据
    setRealTimePoliceList(state,val){
      state.screenRealTimePoliceList = val;
    },
    // 修改地图级别
    setMapLevel(state,val){
      state.screenMapLevel = val;
    },
    // 修改报警状态
    setPoliceType(state,val){
      state.screenPoliceType = val;
    },
    // 修改窗口详情
    setProjectWindowDeail(state,val){
      state.screenProjectWindowDeail = val;
    },
    // 修改项目id
    setProjectId(state,val){
      state.screenProjectId = val;
    },
    // 修改项目设备分类
    setDeviceTypeList(state,val){
      state.screenDeviceTypeList = val;
    },
    // 修改全局省code
    setProvinceCode(state,val){
      state.screenProvinceCode = val;
    },
    // 设备分布地图是否开启
    setDistributionMap(state,val){
      state.screenDistributionMap = val;
    },
    // 修改代理商id
    setDistributionAgentId(state,val){
      state.screenDistributionAgentId = val;
    },
    // 修改工程商id
    setDistributionEngId(state,val){
      state.screenDistributionEngId = val;
    },
    // 是否处于加载点位
    setIsLoading(state,val){
      state.screenIsLoading = val;
    },

  },
  getters:{
  }
}

export default screenStore;
import Vue from 'vue'
import Vuex from 'vuex'
import App from './App'
import router from './utils/route'
import store  from './utils/store'
import ElementUI from 'element-ui';
import VueAMap from 'vue-amap';
import VueAnimateNumber from 'vue-animate-number'
import 'element-ui/lib/theme-chalk/index.css';
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import './assets/custom-css/icon.scss';
import * as echarts from 'echarts'
import PageModal from './components/pageModel';
import BaiduMap from 'vue-baidu-map'
import { getReset } from '@/pages/module-screen/utils/utils'
import vueWorker from 'vue-worker'

//引入组件
import PubTable from './components/pubTable/index.vue';
import NewPubTable from './components/pubTable-new/index.vue';
import Paging from './components/component-block/block-paging.vue';
import Treeselect from "@riophae/vue-treeselect";
import ClickCopy from './components/component-block/block-click-acountcopy.vue';



// 全局utils
import utils /*{ numberInput }*/ from './utils/util';

// 追加线程
// Vue.use(vueWorker, { store })
Vue.use(vueWorker)

// vue指令
Vue.directive('number-input', utils.numberInput);

// 指令
// 监听元素大小变化并获得对应参数
Vue.directive('reset', getReset);

//注册组件
Vue.component('Paging',Paging)
Vue.component('Treeselect',Treeselect)
Vue.component('PubTable', PubTable);
Vue.component('NewPubTable', NewPubTable);
Vue.component('ClickCopy',ClickCopy)
Vue.use(BaiduMap,{ak:'bKGEmLs4E9SGEGQ4NIxcfLESpcrLK5Bg'})
Vue.use(VueAMap);
Vue.use(VueAnimateNumber)
Vue.use(ElementUI);
Vue.use(Vuex);
Vue.config.productionTip = false

Vue.prototype.$PageModal = PageModal;
Vue.prototype.$_echarts = echarts

const app = new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate(){
    Vue.prototype.$bus = this;
  }  
}).$mount('#app');

//过滤器
Vue.filter('Phone',function(value){ 
  if(!value){
	 return ""
  }
  return  value.slice(0,3)+" "+value.slice(3,7)+" "+value.slice(7,11)
})




window.app = app;
window.utils = utils;
app.companyName = '百仁吉科技有限公司';

// 如果localStorage存在Tiken 则需要重新请求Tiken是否过期
const Tiken = localStorage.getItem("brjYunWeiFactoryTiken");
if (Tiken) {
  // 请求http

  // 暂时
  app.$router.push({
    path: app.$route && app.$route.path ? app.$route.path : '/Index',
    query: {}
  });
} else {
  app.$router.push({
    name: 'Login',
    query: {}
  });
}

// 放入地图坐标
window.mapKey = {
  AutoNaviKey: '3b592008854f47146c257ecc6e5b0dc5',     // 高德key
  TencentKey:'ZSOBZ-5LCK3-OOT3Z-3BBHJ-EKHKQ-ZXBQW',       // 腾讯key
  BaiduKey:'bKGEmLs4E9SGEGQ4NIxcfLESpcrLK5Bg',         // 百度key
}
<template>
	<div>
		<div class="search">
			<slot name="search"></slot>
		</div>
		<el-table
		    ref="multipleTable"
		    :data="tableData" 
			:stripe="stripe" 
			:border="border"  
			:size="size" 
			:fit="fit"
			:showHeader="showHeader" 
			:tree-props="childrenPorps" 
			:highlightCurrentRow="highlightCurrentRow"
			:height="storeWindowHeight" 
			@selection-change="selectAll"
			>
			<template v-for="(item,index) in columns" >
				<!--通过render函数返回的自定义-->
				<template v-if="!!item.render">
					<el-table-column    
						:prop="item.prop"        
						:label="item.label" 
						:width="item.width"
						:sortable="item.sortable"
						:header-align="item.headerAlign"
						:align="item.align"
						:minWidth="item.minWidth"
						:fixed="item.fixed"
					>
						<template slot-scope="scope" >
							<custom :render="item.render" :row="scope.row" :index="scope.$index"/>
						</template>
					</el-table-column>
				</template>
				<!--纯文本-->
				<template v-else>
					<el-table-column 
						:key = "index" 
						:prop="item.prop"  
						:label="item.label" 
						:width="item.width" 
						:minWidth="item.minWidth"
						:sortable="item.sortable"
						:header-align="item.headerAlign"
						:align="item.align"
						:type = 'item.type'
						:show-overflow-tooltip="item.tooltip"
						/>
				</template>
			</template>
		</el-table>
		<Paging  @getPage="getPage" :total="total" :pageNo="parms.current" :size="parms.size" />
	</div>
</template>

<script>
import { postFun } from '@/utils/httpFun';
import custom from './custom.vue';
import Loading from '../loading.vue';
import store from '@/utils/store';
export default {
	name: 'PubTable',
	components: {
		custom,
		Loading,
	},
	data() {
		return {
			tableData: [],
			parms:{
				current: 1,
				size: 20 
			},
			total:0,
			storeWindowHeight:''
		}
	},
	props: {
	  
		action: {                // 请求路由
			type: String,
			default: () => ''
		},
		params: {                // 请求参数
			type: Object,
			default: () => ({})
		},
		tableHeight: {
			type: Number,
			default: () => 180
		},
		childrenPorps: {
			type: Object,
			default: () => ({
				children: 'productGetVo',
				hasChildren: 'hasChildren'
			})
		},

		data: {
			type: Array,
			default: () => []
		},
		columns: {
			type: Array,
			default: () => []
		},
		stripe: {
			type: Boolean,
			default: () => false
		},
		border: {
			type: Boolean,
			default: () => false
		},
		size: {
			type: String,
			default: () => 'small'
		},
		fit: {
			type: Boolean,
			default: () => true
		},
		showHeader: {
			type: Boolean,
			default: () => true
		},
		highlightCurrentRow: {
			type: Boolean,
			default: () => true
		},
		isLocationPaging: {
			type: Boolean,
			default: () => false
		},
		isLoading: {
			type: Boolean,
			default: () => false
		}
	},

	created() {
		this.getInfo()
		try{
			this.storeWindowHeight = this.$store.state.windowHeight - this.tableHeight;
		}catch{
			this.storeWindowHeight = this.tableHeight;
		}
	},
	mounted() {
		
	},
	methods: {
        //获取信息
        getInfo(){
		  this.parms.param = this.params
		  postFun(this.action, this.parms , res => {
				if(res.code == 1){
					this.tableData = res.data.records
					this.total = res.data.total 
				}
			})
	    },

		//全选
		selectAll(ev){
			this.$emit("selectionChange",ev)
		},
        
		//清理全选
		clearSelect(){
			this.$refs.multipleTable.clearSelection();
		},

	    //分页
	    getPage(page) {
		   this.parms.size = page.pageSize
           this.parms.current = page.pageNo
		   this.getInfo() 
		},

		//搜索重置
		searchBtn(){
			this.parms.current = 1
			this.getInfo() 
		}
	}
}
</script>
<style>
  .search{
	 margin-bottom:10px;
  }
</style>

<template>
  <div class="common-width common-height">
      <BlockTop></BlockTop>
      <div class="common-space-between" :style='{height:this.$store.state.windowHeight-this.$store.state.topBlockHeight+"px"}'>
        <BlockMenu />
        <div class="blockDiv" :style='{width:this.$store.state.windowwidth-this.$store.state.leftBlockWidth+"px"}'>
          <div class="warp-router">
            <router-view></router-view>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
  import BlockTop from '../components/block-top.vue';
  import BlockMenu from '../components/block-menu.vue';
  export default {
    components: {
      BlockTop,
      BlockMenu,
    },
  }
</script>
<style lang="scss" scoped>
    .blockDiv {
      display:flex;
      padding:8px;
      box-sizing:border-box;
      flex-direction: column;
      background-image: url('@/assets/custom-icon/indexbg.svg');
      background-size: cover;
      background-repeat: repeat;
    }
    .warp-router{
      box-sizing: border-box;
      height: 100%;
      padding:8px;
      background-color: white;
      border-radius:5px;
      position:relative;
    }
</style>
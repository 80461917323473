<template>
  <div class="loading">
    <i class="load-icon el-icon-refresh-left" v-if="!loading && finish=='NO'"></i>
    <i class="load-icon el-icon-loading" v-if="loading"></i>
    <span class="txt" @click="load" v-if="finish==='NO'">点击加载</span>
    <span class="txt-finish" v-if="finish==='YES'">已加载所有数据</span>
  </div>
</template>

<script>
  export default {
    name: 'Loading',
    props: {
      outloading: {
        type: Boolean,
        default: () => false
      },
      finish: {
        type: String,
        default: () => 'NO',
      }
    },
    data() {
      return {
        loading: false,
      }
    },
    watch: {
      outloading(val) {
        this.loading = val;
      }
    },
    methods: {
      load() {
        this.$emit('load-data');
        this.loading = true;
      }
    },
  }
</script>

<style lang="scss" scoped>
.loading {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .load-icon {
    font-size: $fontSizeMedium;
    color: #979DAF;
  }
  .txt {
    display: inline-block;
    padding: 5px;
    cursor: pointer;
    font-size: $fontSizeMini;
    color: #979DAF;
  }
  .txt-finish {
    display: inline-block;
    padding: 5px;
    font-size: $fontSizeMini;
    color: #979DAF;
  }
}
</style>
<template>
    <el-dialog  title="修改密码" :visible.sync="showBlock"  width="400px" :close-on-click-modal="false" :modal="false" :before-close="closedBtn">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="78px" class="demo-ruleForm">
            <el-form-item label="旧的密码" prop="oldpws">
               <el-input v-model="ruleForm.oldpws" placeholder="请输入原来的密码" autocomplete="off" show-password></el-input>
            </el-form-item>
            <el-form-item label="新的密码" prop="newpws">
               <el-input v-model="ruleForm.newpws" placeholder="请输入新的密码" autocomplete="off" show-password></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPws">
               <el-input v-model="ruleForm.checkPws" placeholder="请再次输入新的密码" show-password ></el-input>
            </el-form-item>
            <el-form-item>
               <div class="warp-btn">
                  <el-button @click="resetForm('ruleForm')">重置</el-button>
                  <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
               </div>
            </el-form-item>
            </el-form>
      </el-dialog>
</template>
<script>
   import store from '@/utils/store';
   import { url,api } from '../utils/config';
   import { putFun } from '../utils/httpFun';
   import { hex_md5 } from '../utils/md5';
   export default{
   data(){
      var oldPw = (rule, value, callback) => {
        if (value === '') {
          return callback(new Error('请输入原密码'));
        }else{
          callback();
        } 
      };
      var newPw = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('新的密码不可为空'));
        }else{
         let rule =/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/
         if(rule.test(value)){
            callback();
         }else{
            return callback(new Error('密码格式错误 密码规则密码至少6位 数字加字母'));
         }
          
        }
      };
      var checkPw = (rule, value, callback) => {
         
        if (value === '') {
           return callback(new Error('请再次输入新密码'));
        } else if (value+"" !== this.ruleForm.newpws+"") {
           return callback(new Error('两次输入密码不一致!'));
        } else {
            callback();
        }
      };
      return{
         showBlock:false,
         ruleForm: {
            oldpws: '',
            newpws: '',
            checkPws: ''
         },
         rules: {
            oldpws: [
               { validator: oldPw,  trigger: 'blur' }
            ],
            newpws: [
               { validator: newPw,  trigger: 'blur' }
            ],
            checkPws: [
               { validator: checkPw, trigger: 'blur' }
            ],

         }
      }  
       },
       methods:{
           //提交登录
           submitForm(ruleForm) {
               this.$refs.ruleForm.validate((valid) => {
                  if (valid) {
                     this.submitBtn()
                  } else {
                     return false;
                  }
               });
            },
            async submitBtn(){
                let parms = {
                    oldPassword:hex_md5(this.ruleForm.oldpws+""),
                    password:hex_md5(this.ruleForm.newpws+""),
                    id:store.state.userId,
                    loginType:1
                }
                await putFun(api.other+"/personalCenter/updatePwd",parms,res=>{
                   if(res.code*1==1){
                       this.$message.success('密码修改成功');
                       setTimeout(res=>{
                          app.$router.replace({ path:"/Login", query: {} });
                       },500)
                   }
                })
            },

            //重置输入框
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            //关闭弹窗
            closedBtn(){
               this.showBlock = false
            },
       }
   }
</script>
<style lang="scss" scoped>
   ::v-deep.el-dialog__wrapper{
      background-color: rgba($color: #000000, $alpha: 0.5);
   }
   .warp-btn{
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
   ::v-deep .el-dialog__header{
         height: 23px;
         background: #F8F9FA;
         box-shadow: 0px 1px 0px 0px rgb(237 238 240);
         border-radius: 12px 12px 0 0;
   }
   ::v-deep .el-dialog{
        border-radius: 12px;
   }
   ::v-deep .el-dialog__body{
        padding: 30px 20px 10px 10px;
   }
</style>
<template>
  <div class="root">
    <div v-for="(item,index) in option" :key="index" class="box">
      <div class="text">{{ item.name }}</div>
      <el-switch @change="changeBtn" v-model="item[item.type == 1 && 'oaState' || item.type == 2 && 'shortMessageState' || item.type == 3 && 'voiceState']"/>
    </div>
  </div>
</template>

<script>
// 原生安卓不是那种第三方打包
import {putFun} from '@/utils/httpFun'
import {api} from '@/utils/config'
export default {
  props:{
    user:{
      type:Object,
      default:()=>{}
    }
  },
  data() {
    return {
      value: 1,
      option:[
        {
          name:'公众号接收',
          type:1,
          oaState:false
        },
        {
          name:'短信接收',
          type:2,
          shortMessageState:false
        },
        {
          name:'语音接收',
          type:3,
          voiceState:false
        },
      ]
    };
  },
  mounted(){
    console.log("测试",this.user);
    this.option[0].oaState = this.user.factoryUserVo.oaState == null || this.user.factoryUserVo.oaState == false || this.user.factoryUserVo.oaState == 0 ?false:true;
    this.option[1].shortMessageState = this.user.factoryUserVo.shortMessageState == null || this.user.factoryUserVo.shortMessageState == false || this.user.factoryUserVo.shortMessageState == 0 ?false:true;
    this.option[2].voiceState = this.user.factoryUserVo.voiceState == null || this.user.factoryUserVo.voiceState == false || this.user.factoryUserVo.voiceState == 0 ?false:true;

    this.$emit('SetPageSize',350)
    this.$emit("SetTitle",'通知设置')
    this.$emit("SetPageActions", []);
  },
  methods:{
    changeBtn(){
      let param = {
        oaState:this.option[0].oaState?1:0,
        shortMessageState:this.option[1].shortMessageState?1:0,
        voiceState:this.option[2].voiceState?1:0
      }
      putFun(`${api.other}/personalCenter/messageNotice`,param,res=>{})
    }
  }
}
</script>

<style lang="scss" scoped>
  .box{
    margin-bottom:20px;
    widows: 100%;
    display: flex;
    justify-content:space-between;
    .text{
      font-size: 14px;
      color: #4D5C72;
    }
  }
  :v-deep .core-modal-wrap .core-modal .core-modal-footer{
    display: none!important;
  }
</style>
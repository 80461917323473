<template>
  <el-dialog  title="账户信息" :append-to-body="true" :visible.sync="showBlock"  width="500px"  :show-close="true" :before-close="closedBtn"  :close-on-click-modal="false">
    <div class="warp-div">
      <el-form ref="form"  label-width="50px" label-position="left" >
          <el-form-item label="账号" prop="name">
             <el-input class="left-input" placeholder="请输入用户账号" v-model="account" disabled clearable ></el-input>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input  class="left-input"  placeholder="请输入姓名" v-model="name"  ></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="name">
              <el-select  class="left-input" v-model="gender"  placeholder="请输入性别"  clearable style="width:100%;">
                <el-option label="男" :value="1" />
                <el-option label="女" :value="0" />
              </el-select>
          </el-form-item>
          <el-form-item label="部门" prop="name">
             <el-input class="left-input" placeholder="请输入部门" v-model="departName" clearable ></el-input>
          </el-form-item>
          <el-form-item label="头像" prop="name">
             <UploadImg ref="headerImg" keyVal="headerImg" @getUpload="getUpload" limit="1"></UploadImg>
          </el-form-item>
          <el-form-item label="" prop="name">
             <el-button @click="changeBtn" class="btn">保存信息</el-button>
          </el-form-item>
      </el-form>
    </div>
  </el-dialog>
</template>
<script>
// 原生安卓不是那种第三方打包
import {putFun} from '@/utils/httpFun'
import { url, api } from '../utils/config';
import UploadImg from '@/components/component-block/block-uploadImgNew.vue';
import { getFun, postFun } from '../utils/httpFun';
export default {
  components:{
    UploadImg
  },
  data() {
    return {
      user:{},
      showBlock:false,
      account:"",
      name:"",
      gender:"",
      departName:"",
      img:''
    };
  },
  methods:{
    //设置默认信息
    open(data){
      console.log(data)
      
      this.showBlock = true;
      // this.account = data.account
      // this.name = data.factoryUserVo.name
      // this.gender = data.factoryUserVo.gender
      // this.departName = data.factoryUserVo.departName
      
      console.log(data)

      this.getMessage();
    },
    getMessage(){
      getFun(api.other+'/account/factoryUser/basic',{},res=>{
        console.log('获取当前登录信息',res)

        let parms = {};
        this.user = res.data;
        this.gender = this.user.gender;
        this.name = this.user.name;
        this.account = this.user.account;
        this.departName = this.user.departId;
        console.log('测=====试',this.user,this.name,this.departName)
        if(!!!this.user.photoPreviewUrl){
          //默认头像
          parms =  {
            showImg: ['https://yunen.test.brjkj.cn:9000/brj-test/20230428…0fe5bf41d44d6eb15e35a92422f93b4e75b65541d611711a4'],
            uploadVal: ['20230428174803-887']
          }
        }else{
          parms = {
            showImg:[this.user.photoPreviewUrl],
            uploadVal:[this.user.photoUrl]
          }
        }

        this.$nextTick(res=>{
            this.$refs.headerImg.setList(parms)
        })
      })
    },
    //上传照片
    getUpload(e){
      this.img = e.val[0];
    },

    closedBtn(){
       this.showBlock = false;
    },
    changeBtn(){
      let param = {
        name:this.name,
        gender:this.gender,
        photoUrl:this.img,
        departId:this.departName,
        id:this.user.id
      }

      if(!!!this.img){
        delete param.photoUrl;
      }

      postFun(api.other+'/account/updateFactoryUser',param,res=>{
        console.log('保存信息',res)
        if(res.data){
          this.$message.success('保存成功');
          this.showBlock = false;
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn{
    color:#fff;background-image: linear-gradient(270deg, #4AA7FF 0%, #246EFF 100%);border-radius: 6px;
    border: none;
  }
  ::v-deep .el-dialog__header{
      background: #F8F9FA;
      box-shadow: 0px 1px 0px 0px rgb(237 238 240);
      border-radius: 12px 12px 0 0;
   }
   ::v-deep .el-dialog{
       border-radius: 12px;
   }
</style>
import Vue from 'vue'
import Vuex from 'vuex'
import {conText} from '@/utils/configText'
import screenStore from '@/pages/module-screen/utils/screen-store'

Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
       ...screenStore.state,
       pageSize:10,         //每页请求数量
       windowwidth:0,       //浏览器的宽度
       windowHeight:0,      //浏览器的高度
       topBlockHeight:46,   //顶部块的高度
       leftBlockWidth:170,  //左侧块的宽度
       blockTruewidth:180,  //设备分类栏的宽度
       menuList:[],         //系统菜单
       menuID:0,            //菜单选中的主ID
       menuSonID:0,         //菜单选中的子ID 
       listMenuList:[],     //选中的数据
       moduleLists: [],     //菜单模块
       operator:"",         //操作人
       user:{},             //登录用户
       userId:"",           //用户的USERid
       tableDataLength:0,   //数据长度 
       authorsRoleButtons: ['编辑', '删除', '禁用'],
       authorsAccountButtons: ['编辑', '重置密码', '禁用'],
       authorsAgentButtons: ['查看', '禁用'],
       authorsEngeerButtons: ['查看', '禁用'],
       authorProductAddButtons: ['编辑', '禁用'],
       isOne:true,
       isScan:true,         // 是否已经开启扫码窗口
       //常用的配置列表
       configList:conText,
    },
    mutations:{
        ...screenStore.mutations,
        //设置浏览器窗口的信息
        setWindowBtn(state,val){
            state.windowwidth = val.clientWidth
            state.windowHeight = val.clientHeight
        },
        setMenuList(state,val){
            console.log('---------',val.menuList)
            state.menuList = val.menuList
        },
        setMenuIDBtn(state,val){
            state.menuID = val.menuID
        },
        setMenuSonIDBtn(){
            state.menuSonID = val.menuSonID
        },
        setModuleLists(state, val) {
            state.moduleLists = val;
        },
        //设置当前操作人
        setOperator(state,val){
            state.operator = val.operator
        },
        setLength(state,val){
            state.tableDataLength = val.tableDataLength
        },
        setuserId(state,val){
            if(!state.isOne)return;
            state.userId = val.userId
            state.isOne = false;
        },
        setIsScan(state,val){
            state.isScan = val;
        },
        setUser(state,val){
            state.user = val;
        }
},
    getters: {
        ...screenStore.getters,
        getModuleLists: state => state.moduleLists,
    }
})
export default store
const api={
    HttpUrl:"https://pro.brjxf119.com/api",                           //接口域名   生产
    RealUrl:'pro.brjxf119.com',                                      // 实时报警请求 域名

    // RealUrl:'yunen.test.brjkj.cn:30000',
    // HttpUrl:'http://yunen.test.brjkj.cn:30000',                    //接口域名       测试

    // HttpUrl:'http://yunen.test.brjkj.cn:30000',                    //接口域名       本地

    // HttpUrl:'http://192.168.1.26:7000',                           //接口域名        本地

    other:"/factory-aggre-service",   //"/operate-aggre-service",     //非登录的前缀
    loGin:"/auth-service",                                            //登录的前缀
    bucketName:"brj-test",
    objectName:"importTemplate/deviceImportTemplate.xlsx",            //下载表格传递的参数
}

const urlAddress = {
    UPLOADINGAS:api.HttpUrl+"/minio-service/minio/uploadSingle?bucketName=brj-test",                   //上传图片单张
    UPLOADINGMO:api.HttpUrl+"/minio-service/minio/uploadMultipleFile?bucketName=brj-test",             //上传图片多张
    SHOWIMG:"/minio-service/minio/preview",                                                            //预览图片
    SHOWIMGS:"/minio-service/minio/previewBatch",                                                      //预览图片多张
    DOWNLOAD:"/minio-service/minio/download",                                                          //下载表格
    DEVICEPROJECT:api.HttpUrl + api.other+"/deviceManager/exportDeviceList",                                                   //导出入库列表的数据
}

//常用的正则表达式配置
const rules = {
    NONEMPTY:/^[\s\S]*.*[^\s][\s\S]*$/,                                                               //非空验证
    PHONE:/^1[3456789]\d{9}$/,                                                                        //手机号验证
    EMAIL:/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,                       //邮箱验证
    IDCARD:/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,    //身份证正则
    PASSWORD:/^[a-z0-9A-Z]{8,16}$/,                                                                   //密码的正则
    CHINESE:/^[\u4E00-\u9FA5]+$/,                                                                     //只允许输入中文 
    POSITVIE:/^\d+$/,                                                                                 //大于等于0的正整数  
    ENGCHENHSH:/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/                                         //匹配6~12位由数字和26个英文字母混合而成的密码                                                              
}

//向外抛出
module.exports = {
    urlAddress,
    rules:rules,
    api:api,
}
import provices from './china-map-radio'
import store from '@/utils/store'
import music from '@/assets/screen/music/warns.mp3'
// const turfProjection = require('@turf/projection');
// const turfPoint = require('@turf/helpers');

// 监听对应dom改变的方法
// 创建WeakMpa用于存放dom和回调 weakMap 不会造成内存溢出
const weakMap = new WeakMap();
// 创建全局监视者
const resizeObserve = new ResizeObserver(entries => {
  // 找到对应的dom 将制度数据丢过去
  for (let index in entries) {
    let entrie = entries[index];
    // 拿到对应数据
    let obj = weakMap.get(entrie.target);

    // 如果没有回调就会报错
    if (!!!obj.callback) {
      new Error('reset指令必需传递一个回调');
    } else {

      let key = '';

      // 默认borderBoxSize
      if (!!!obj.arg) {
        key = 'borderBoxSize';
      } else {
        key = obj.arg;
      }

      obj.callback(entrie[key]);
    }
  }
});


/**
 * 
 * @tag date dateTime  获取指定时间 支持属性： yyyy-MM-dd HH:mm:ss ms week 
 * @param { 时间对象 } date 
 * @param { 转换时间的格式 } format 
 * @param { 是否补零 } isPad 
 */
export function getDate(date, format, isPad) {

  // 将时间进行归一化
  function _dateFormat(format) {

    if (typeof format == 'Funtion') {
      return format;
    }

    if (format == 'date') {
      format = 'yyyy-MM-dd';
    }

    if (format == 'dateTime') {
      format = 'yyyy-MM-dd HH:mm:ss';
    }

    const formatFun = (dateInfo) => {
      const { yyyy, MM, dd, HH, mm, ss, ms, week } = dateInfo;

      return format.replaceAll('yyyy', yyyy)
        .replaceAll('MM', MM)
        .replaceAll('dd', dd)
        .replaceAll('HH', HH)
        .replaceAll('mm', mm)
        .replaceAll('ss', ss)
        .replaceAll('ms', ms)
        .replaceAll('week', week)
    }

    return formatFun;
  }


  let dateFormatFun = _dateFormat(format);

  let week = ["日", "一", "二", "三", "四", "五", "六"];

  let dateInfo = {
    yyyy: date.getFullYear(),
    MM: date.getMonth() * 1 + 1,
    dd: date.getDate(),
    HH: date.getHours(),
    mm: date.getMinutes(),
    ss: date.getSeconds(),
    ms: date.getTime(),
    week: week[date.getDay()]
  }

  // 补零
  if (isPad) {

    let keyArr = Object.keys(dateInfo);

    for (let key in dateInfo) {
      if (key == 'week') continue;
      let len = key.length;
      dateInfo[key] = (dateInfo[key] + '').padStart(len, '0')
    }
    // console.log('date',dateInfo)
  }

  return dateFormatFun(dateInfo);
}

/**
 * @tag 设置方法的this指向
 * @param { 对象this } _this 
 * @param { 方法名字 } methodArr 
 */
export function setMethod(_this, ...methodArr) {

  // 判断是否为一个数组
  if (Array.isArray(methodArr[0])) {
    methodArr = methodArr[0];
  }

  methodArr.forEach(item => {
    try {
      _this[item] = _this[item].bind(_this);
    } catch {
      throw new Error('没有找到' + item + '这个方法')
    }
  })
}

/**
 * @tag 单列模式
 * @param { 类名 } className 
 * @returns 返回类对象 
 */
export function singleton(className) {
  let ins;

  return new Proxy(className, {
    construct(target, args) {
      if (!ins) {
        ins = new target(...args);
      }

      return ins;
    }
  })
}

/**
 * @tag 经纬数组转换
 * @param { 指定dom转换 } dom 
 * @param { 需要转换的经纬数组 } lnglatArr 
 * @returns 返回dom对应坐标 按传入数组计算
 */
export function conversion(dom, lnglatArr = []) {
  console.log('inglatArr',lnglatArr)

    let fontSize =  document.documentElement.style.fontSize
    fontSize = fontSize.replaceAll('px','') *1;

    const parentRect = dom.parentElement.getBoundingClientRect();
    const rect =dom.getBoundingClientRect();
    // 固定坐标比
    let stableRatio = 0.5626;
    // let stableRatio = 0.5330;
    let h = rect.width * stableRatio;
    let r_w;
    let r_h;
    let t_w;
    let t_h;
    let renderData = [];
    dom.style.margin = 'auto';
    if (parentRect.height > h) {
      dom.style.height = `${h / fontSize}rem`;
      r_w = rect.width;
      r_h = h;
    } else {
      h = parentRect.height / stableRatio;
      dom.style.width = `${h/fontSize}rem`;
      r_w = h;
      r_h = parentRect.height;
    }
    // t_w = r_w * 0.0648;
    t_w = r_w * 0.0468;
    // t_h = t_w * 0.631;
    t_h = t_w * 1.381;
    for (const n of lnglatArr) {
      const rect = provices[n.province];

      // 中国静态地图上最终打上的位置 
      const item = {
        ty: `${(rect.ty * r_h + (t_h *1.2 / 2) * rect.transformY)/fontSize}rem`,
        tx: `${(rect.tx * r_w + (t_w * 1.2 / 2) * rect.transformX)/fontSize}rem`,
        // ty: `${rect.ty * r_h + (t_h *1.5 / 2) * rect.transformY + 6}px`,
        // tx: `${rect.tx * r_w + (t_w * 0.8 / 2) * rect.transformX + 6}px`,
      }

      n.meta = item;
    }
    console.log('renderData',renderData)

    return lnglatArr;
}

/**
 * @tag 判断参数是否必须放入
 * @param { 参数的名字 } key 
 */
export function mustArg(key){
  throw new Error(`${key}该参数必须传`)
}

/**
 * @tag 唯一的uid
 * @param { 长度 } len 
 * @param { 基数 } radix 
 * @returns 
 */
export function getUid(len, radix){
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  const uuid = [];
  let i;
  radix = radix || chars.length;
  if (len) {
    for (i = 0; i < len; i++) {
      uuid[i] = chars[0 | (Math.random() * radix)];
    }
  } else {
    let r;
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";
    for (i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (Math.random() * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("");
};

/**
 * 设置 请求完打点之后的数据
 * @param { 打点请求之后的数组 数组类型 } data 
 * @param { 用户自定义内容 } meta 
 * @returns '返回一个数组'
 */
export function setMarkerData(data,meta = {}){
  console.log('========处理的数据',store.state.screenPoliceType)
  let list = [];
  try{
    list = [...data];
  }catch{}
  return list.map(item=>{
    if(item.longiLatitude == null)return undefined;
    else 
      return {
        uid:getUid(32,10),
        policeType:store.state.screenPoliceType,
        province:!!!item.areaAddress?'':item.areaAddress.split(',')[0],
        areaAddress:item.areaAddress,
        codeAddress:item.codeAddress,
        projectId:item.projectId,
        projectIds:item.projectIds,
        total:item.total,
        number:item.number,
        longiLatitude:item.longiLatitude,
        toLongiLatitude:item.toLongiLatitude,
        meta:meta,    // 用户自定义内容
      }
  }).filter(item=>item)
}

/**
 * @tag 监听绑定元素的变化 并获取数据
 */
export const getReset = {
  // 指令的挂载 
  bind: function (el, binding, vnode, oldVnode) {
    // 监听绑定元素
    resizeObserve.observe(el);
    weakMap.set(el, { callback: binding.value, arg: binding.arg })
  },
  unbind: function (el, binding, vnode, oldVnode) {
    resizeObserve.unobserve(el);
  }
}

/**
 * @tag　创建一个居中提示框
 * @param { 提示文字 } text 
 * @param { 动画时长 } duration 
 */
export function prompt(text,duration = 1000){
  let divMain = document.createElement('div');
  divMain.setAttribute('style','overflow: hidden;width:100%;height:100%;position: absolute;z-index:999;top:0;left:0;display:flex;justify-content: center;align-items: center;');
  let divCenter = document.createElement('div');
  divCenter.setAttribute('style','width:25rem;height:7rem;color:#fff;background:#2e2e2ec2;display: flex;justify-content: center;align-items: center;border-radius: 16px;');
  let span = document.createElement('span');
  span.setAttribute('style','font-size: 16px;font-weight: bold;')
  span.innerText = text;
  divCenter.appendChild(span);
  divMain.appendChild(divCenter);

  console.log('dom',divMain);
  document.body.append(divMain);

  setTimeout(()=>{
    document.body.removeChild(divMain)
  },duration)
}

/**
 * 计算百分比
 * @param { 数量 } num 
 * @param { 总数 } total 
 * @param { 是否需要百分号 } isPercent
 * @returns 百分比
 */
export function percentage(num,total,isPercent = true){

  if(num == 0 && total == 0){
    if(isPercent){
      return '0%';
    }else{
      return '0.00'
    }
  }

  if(isPercent)
    return (num/total*100).toFixed(2)+'%';
  else
    return (num/total*100).toFixed(2);
}

/**
 * @tag 批量请求
 * @param { 批量请求的数组 } requestList 
 * @param { 每次请求的次数 } loopNum 
 * @param { 请求完成的回调 } succeeCallback 
 * @param { 失败回调 } errorCallback 
 * @param { 成功的参数 请求就是空 } args 
 */
export function batchrequest(requestList,loopNum = 5,succeeCallback,errorCallback,index = 0){
 let requestArr = requestList.splice(0,loopNum);

  for(let i=0;i<requestArr.length;i++){
    requestArr[i] = requestArr[i](index+i);
  }

  index += requestArr.length - 1;
  // debugger;

 Promise.all(requestArr).then((res)=>{
  if(requestList.length === 0){
   !!succeeCallback && succeeCallback([]);
    return;
  }
  console.log('===============进入=============================')
   batchrequest(requestList,loopNum,succeeCallback,errorCallback,index);
 }).catch((err)=>{
   !!errorCallback && errorCallback(err);
   return;
 })
}

// /**
//  * @tag 无限滚动
//  * @param { 滚动容器的dom } containerDom 
//  * @param { 滚动项的dom } itemDom 
//  * @param { 时长 } step 
//  */
// export function infiniteScrolling(containerDom,itemDom,step = 1){
//   // 获取滚动容器和滚动项元素
//   const container = containerDom;
//   const items = itemDom;

//   // 复制滚动项以实现无限循环效果
//   items.innerHTML += items.innerHTML;

//   // 设置滚动容器的初始滚动位置
//   let scrollPos = 0;
//   container.scrollTop = scrollPos;

//   // 使用 requestAnimationFrame 实现滚动效果
//   function scroll() {
//     scrollPos++;

//     if (scrollPos >= items.offsetHeight / 2) {
//       scrollPos = 0;
//       container.scrollTop = scrollPos;
//       requestAnimationFrame(scroll); // 无痕循环滚动
//       return;
//     }

//     container.scrollTop = scrollPos;
//     requestAnimationFrame(scroll);
//   }

//   scroll();
// }

// 声音控制
let audoContext = null;
let audio = null;
let track = null;

export function initMusic(){
  audio = new Audio(music);
  audio.loop = true; // 循环播放
}

export function destroyMusic(){
  audio.pause();
  audio = null;
}

// 控制声音
export function controlMuisc(state = 'play'){
  if(state == 'play'){
    // 开启声音
    audio.play();
  }else if(state == 'end'){
    // 关闭声音
    audio.pause();
  }
}

// 销毁控制器
                               
export default {
  getDate,
  setMethod,
  singleton,
  conversion,
  mustArg,
  getUid,
  setMarkerData,
  prompt,
  percentage,
  batchrequest,
  controlMuisc,
  initMusic,
  destroyMusic
}
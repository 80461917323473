<template>
  <div>
    <div class="core-modal-mask"></div>
    <div class="core-modal-wrap">
      <transition :name="'sidelip-down-modal'" @after-leave="afterLeave" @after-enter="afterEnter">
        <div class="core-modal" :style="{'width':width+'px'}" v-if="state > -99">
          <div class="header goback">
            <span class="theme">{{title}}</span>
            <span class="txt" @click="close">
              <i style="font-size:30px;" class="el-icon-close"></i>
            </span>
          </div>
          <div class="core-modal-body"  >
            <slot></slot>
          </div>
          <div class="core-modal-footer" v-if="actions.length>0">
            <el-button  class="page-modal-btn" :loading="actionState[item.action]"  @click.native="item.handle()"
              v-for="(item,index) in actions"
              :key="index"
              :type="item.theme"
              :icon="item.icon"
              size="medium"
            >{{item.text}}</el-button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "./index.scss";

</style>
<script>
export default {
  components: {

  },
  props: {

  },
  data() {
    return {
      state: -99,
      actions: [],
      title: '',
      width: 600,
      actionState: {},
      closeCallBack(){}
    }
  },
  computed: {

  },
  created: function () {

  },
  beforeMount: function () {

  },
  mounted: function () {
    this.state = -1;
  },
  beforeDestroy: function () {

  },
  destroyed: function () {
    document.body.removeChild(this.$el);
  },
  methods: {
    hide() {
      this.state = -99;
    },
    close(){
      this.hide();
      this.closeCallBack();
    },
    setTitle(title) {
      this.title = title
    },
    setCloseCallBack(cb=()=>{}){
      this.closeCallBack = cb;
    },
    setPageActions(actions) {
      this.actions = actions;
      for (let a of this.actions) {
        if (!!a.action) {
          this.actionState[a.action] = false;
        }
      }
    },
    setSize(width) {
      this.width = width;
    },
    afterLeave() {
      this.$destroy();
    },
    afterEnter() {
        this.state = 0;
    },
    setActionsState(action, state) {
      this.actionState[action] = state;
      this.$forceUpdate();
    },
  },
  watch: {

  },
  directives: {

  }
}
</script>
<style lang="scss" scoped>

</style>

<template>
    <div class="common-width common-items-center common-space-between common-pading indexTop" :style='{height:this.$store.state.topBlockHeight+"px"}'>
            <div class="title">
                <el-avatar shape="square" size="medium" :src="logoIco[0]"></el-avatar>
                <span class="txt">{{companyName}}</span>
            </div>
            <div class="common-items-center">
              <div class="view" @click="viewScreenBtn">可视化大屏</div>
              <div class="dataTime">{{dataTime}}</div>
                  <img class="header" :src="portrait || require('@/assets/custom-icon/header.png')"/>
                  <span class="user">{{userName}}</span>
                  <el-popover  placement="bottom-end" width="346" trigger="hover">
                      <div class="block">
                           <div class="common-space-around img">
                               <img class="headerImg" :src="portrait ||  require('@/assets/custom-icon/header.png')"/>
                           </div>
                           <div class="nickname">{{userName}}</div>
                           <div class="warpList">
                              <div class="common-space-between list">
                                  <div class="left listMark">手机号</div>
                                  <div class="right listMark">{{mobile}}</div>
                              </div>
                              <div class="common-space-between list">
                                  <div class="left listMark">所属部门</div>
                                  <div class="right listMark">{{departName}}</div>
                              </div>
                              <div class="common-space-between list">
                                  <div class="left listMark">所属角色</div>
                                  <div class="right listMark">{{rolesName}}</div>
                              </div>
                              <div class="common-space-between list">
                                  <div class="left listMark">近期登录</div>
                                  <div class="right listMark">{{loginTime}}</div>
                              </div>
                           </div>
                           <div class="eidthBlock">
                              <div class="common-space-between common-pointer list" @click="AccoutBtn">
                                  <div class="left listMark" style="color:#2D7CFF">账户信息</div>
                                  <div class="right listMark"><i class="el-icon-arrow-right"></i></div>
                              </div>
                              <div class="common-space-between common-pointer list" @click="pushBtn">
                                  <div class="left listMark">通知设置</div>
                                  <div class="right listMark"><i class="el-icon-arrow-right"></i></div>
                              </div>
                              <div class="common-space-between common-pointer list" @click="SafetyBtn">
                                  <div class="left listMark" style="color:#2D7CFF">账户安全</div>
                                  <div class="right listMark"><i class="el-icon-arrow-right"></i></div>
                              </div>
                              <div class="common-space-between common-pointer list" @click="passwordUpdate">
                                  <div class="left listMark">密码修改</div>
                                  <div class="right listMark"><i class="el-icon-arrow-right"></i></div>
                              </div>
                              <div class="common-space-between common-pointer list" @click="loginoutClick">
                                  <div class="left listMark loginout">安全退出</div>
                              </div>
                           </div>
                      </div>
                      <span slot="reference" class="el-dropdown-link">
                          <span class="name">{{name}}</span><i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                  </el-popover>
                  <span class="full el-icon-fullscreen" @click.stop="fullScreen"></span>
            </div>
            <!--修改密码-->
            <UpdataPw ref="password"></UpdataPw>
            <!--账户安全-->
            <Safety ref="Safety"></Safety>
            <!--账户信息-->
            <Account ref="Account"></Account> 
    </div>
  </template>
  <script>
      import { getDateTime } from '@/utils/util'
      import { api,urlAddress } from '@/utils/config'
      import { getFun, postFun } from '@/utils/httpFun'
      import { resolve } from '@antv/x6/lib/registry/router/manhattan/options'
      import UpdataPw from './block-pword.vue'
      import BlockInfo from './block-info.vue'
      import Safety from './block-safety.vue';
      import Account from './block-account.vue'
      export default{
          components:{
            UpdataPw:UpdataPw,
            Safety:Safety,
            Account:Account
          },
          data(){
             return{
                 companyName: '',
                 userName: '',
                 dataTime:"",
                 name:"",
                 nickName:"",
                 mobile:"",
                 departName:"",
                 rolesName:"",
                 loginTime:"",
                 fullScreenSign: 'N',
                 user:"",
                 logoIco:[],
                 portrait:'',
              }
          },
          created(){
                setInterval(res=>{
                    this.dataTime = getDateTime()
                },1000)
                this.$bus.$off('open-newpassword');
                this.$bus.$on('open-newpassword',res=>{
                    this.passwordUpdate()
                })
                this.getMess()

                this.$bus.$off('user-getMess');
                this.$bus.$on('user-getMess',res=>{
                    this.getMess();
                    this.companyNameFun();
                })
          },
          methods:{
                // 可视化大屏
                viewScreenBtn() {

                    // 本地刷新造数据
                    let obj = {
                        createTime: new Date(),
                        id:-99,
                        image:'-----',
                        name:'可视化大屏',
                        orderNo:1,
                        parentId:0,
                        type:1,
                        webUrl:'Screen',
                        params:{routeName:this.$route.name}
                    }

                    localStorage.setItem("LeftNavBtnParms",JSON.stringify(obj))
                    window.localStorage.setItem('whichScreen','newScreen');

                    let routeName = '';
                    if(this.$route.name == 'Warehousing'){
                        routeName = 'ProductAdd';
                    }else{
                        routeName = this.$route.name
                    }

                    console.log('===========routeanName',routeName,this.$route.name)

                    // 本地大屏
                    this.$router.replace({
                        name:'Screen',
                        params:{
                         routeName:routeName
                        }
                    })

                    // const href = window.location.href;
                    // const token = localStorage.getItem("brjYunWeiFactoryTiken") || '';
                    // const redirect = encodeURIComponent(`href=${href}&token=${token}&factoryName=${this.companyName}&platform=factory`);
                    // window.location.href = `${api.Bigscreen}${redirect}`;
                },

                //获取个人信息
                async getMess(data=false){
                    let parms = {
                        loginType:1
                    }
                    await getFun(api.other+"/personalCenter/getPersonCenterInfo",parms,res=>{
                        this.companyNameFun()  //公司信息
                        let dataMess = res.data || {}
                        this.$store.commit({
                                type:"setOperator",
                                operator: dataMess.factoryUserVo && dataMess.factoryUserVo.name
                        })
                        this.$store.commit({
                                type:"setuserId",
                                userId:dataMess.factoryUserVo && dataMess.factoryUserVo.id
                        })
                        this.$store.commit('setUser',dataMess.factoryUserVo)
                        this.mobile=dataMess.factoryUserVo && dataMess.factoryUserVo.mobile
                        this.departName = dataMess.factoryUserVo && dataMess.factoryUserVo.departId
                        this.userName =  dataMess.factoryUserVo && dataMess.factoryUserVo.name
                        this.portrait = dataMess.factoryUserVo && dataMess.factoryUserVo.photoPreviewUrl
                        app.userName = this.userName
                        this.loginTime = dataMess.loginTime
                        this.user = dataMess;
                        this.$store.commit('setuserId', { userId: dataMess.factoryUserVo && dataMess.factoryUserVo.userId });
                        let infoMess = dataMess.factoryUserVo && dataMess.factoryUserVo.roles
                        let rolesName = ''
                        for(var i in infoMess){
                            if(i==0){
                                rolesName = infoMess[i].name
                            }else{
                                rolesName = rolesName+"-"+list[i].name
                            }
                        } 
                        this.rolesName = rolesName      
                        resolve()
                        if(data){
                            new this.$PageModal(BlockInfo,{
                                    props:{
                                        user:this.user
                                    }
                                },
                                data=>{
                            
                                }
                            )
                        }
                    })
                },

                //获取公司名字
                async companyNameFun(){
                await getFun(api.other+"/factory/getFactoryNavigation",{},res=>{
                    this.lookPict(res.data.icon) 
                    this.companyName = res.data.factoryName
                    this.logoIco.push(res.data.iconUrl);
                    // console.log('===========',this.logoIco)
                })
                },
             
                //查看图片
                lookPict(ev){
                    const params = {
                        bucketName: api.bucketName,
                        objectNames: ev.split(",")
                    };
                },

                //账户安全
                SafetyBtn(){
                    this.$refs.Safety.showBlockM(this.user)
                },

                //账户信息
                AccoutBtn(){
                    this.$refs.Account.open(this.user)
                },

                //推送设置 
                pushBtn() {
                  this.getMess(true);
                },

                //修改密码
                passwordUpdate() {
                  this.$refs.password.$data.showBlock = true;
                  this.$fun
                },
                //退出
                loginoutClick() {
                    app.$confirm(`你确认要退出登录吗, 是否继续?`, '提示',{
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.loginout();
                    }).catch(() => {
                        console.log('取消退出');
                    });
                },
                async loginout() {
                    let parms = {
                            platform:2,    
                            model:1
                    }
                    await postFun(api.loGin+"/auth/logOut",parms,res=>{
                        console.log("退出",res)
                    });
                    sessionStorage.clear();
                    localStorage.removeItem('brjYunWeiFactoryTiken');
                    localStorage.removeItem('LeftNavBtnParms')
                    setTimeout(() => {
                        // location.reload();
                        this.$router.push({
                        name: 'Login',
                        query: {}
                        });
                    }, 500);
                },
                //全屏或者关闭全屏
                fullScreen() {
                    if (!utils.isFullScreen()) {
                        utils.entryFullScreen(document.body);
                    }
                    if (utils.isFullScreen()) {
                        utils.exitFullScreen();
                    }
                }
           }

      }
  </script>
  <style lang="scss" scoped>
      .indexTop{
          background: $fontColorWhite;
          box-shadow: 4px 4px 15px 0px rgba(88,136,210,0.14);
          position: relative;
          z-index:11;
      }
      .title {
          display: flex;
          align-items: center;
          .circle {
              display: inline-block;
              width: 46px;
              height: 46px;
              border-radius:5px;
              margin-right:12px;
          }
          .txt {
              display: inline-block;
              font-size: $fontSizeMedium;
              font-weight: bold;
              color: #0A1733;
              margin-left: 10px;
          }
      }
      .view {
          margin-right:20px;
          font-size: $fontSizeSmall;
          color: white;
          background-image: linear-gradient(270deg, #4AA7FF 0%, #246EFF 100%);
          box-shadow: 2px 2px 12px 0px rgba(66, 155, 255, 0.55);
          padding: 5px 15px;
          border-radius:4px;
          font-weight: 400;

          cursor: pointer;
      }
      .dataTime{
          font-size: $fontSizeSmall;
          color: #212433;
          padding-right: 1.875rem;
          letter-spacing: 0;
      }
      .header{
          height: 30px;
          width: 30px;
          cursor: pointer;
          margin-right:16px;
      }
      .full{
          height:30px;
          width: 30px;
          margin: 0 15px;
          transform: scale(1.2);
          cursor: pointer;
      }
      .el-icon-arrow-down{
          color: $fontColorBlack;
          cursor: pointer;
      }
      .name{
          margin-left: 5px;
          font-family: PingFangSC-Regular;
          font-size: 16px;
          color: $fontColorBlack;
          letter-spacing: 0;
      }
      //个人信息
      .block{
          padding: 0 10px;
          box-sizing: border-box;
         .img{
           padding-top: 30px;
         }
         .headerImg{
          width: 96px;
          height: 96px;
         }
         .nickname{
              font-family: PingFangSC-Semibold;
              font-size: 18px;
              color: #4D5C72;
              letter-spacing: 0;
              line-height: 16px;
              font-weight: 600;
              text-align: center;
              padding-top: 20px;
              padding-bottom: 27px;
              border-bottom:1px solid rgba(202,202,202,0.22);
         }
         .warpList{
            padding-top: 27px;
            padding-bottom: 7px;
          .listMark{
              opacity: 0.7;
              font-family: PingFangSC-Regular;
              font-size: 14px;
              color: #4D5C72;
              letter-spacing: 0;
              font-weight: 400;
              padding-bottom: 20px;
          }
         }
         .eidthBlock{
              border-top: 1px solid rgba(202,202,202,0.22);
              padding-top: 27px;
              .listMark{
                  opacity: 0.7;
                  font-family: PingFangSC-Regular;
                  font-size: 14px;
                  color: #4D5C72;
                  letter-spacing: 0;
                  font-weight: 400;
                  padding-bottom: 20px;

                  &.loginout {
                      color: #e4393c;
                      cursor: pointer;
                  }
              }
         }
      }

  </style>

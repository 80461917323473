<script type="text/javascript">
	export default {
	  name:"custom",
	  functional: true,
	  props: {
	    row: Object,
	    render: Function,
	    index: Number
	  },
	  render: (h, data) => {
	    const params = {
	      row: data.props.row,
	      index: data.props.index
	    };
	    let render = data.props.render;
	    return render(h, params);
	  }
	}
</script>
<template>
  <el-dialog :append-to-body="true" title="更改手机号" :visible.sync="showBlock" width="400px" :show-close="true"
    :before-close="closedBtn" :close-on-click-modal="false">
    <el-form :model="ruleForm" status-icon label-position="left" :rules="rules" ref="ruleForm" label-width="78px" class="demo-ruleForm">
      <el-form-item label="旧手机号">
        <el-input type="text" v-model="ruleForm.oldPhone" disabled placeholder="请输入原来手机号"></el-input>
      </el-form-item>
      <el-form-item label="新手机号" prop="newPhone">
        <el-input type="text" v-model="ruleForm.newPhone" placeholder="请输入新手机号"></el-input>
      </el-form-item>
      <el-form-item label="验证码" prop="checkPws">
        <el-input v-model="ruleForm.checkPws" placeholder="请输入验证码"></el-input>
        <span v-if="isCode" @click="getCode" class="code">获取验证码</span>
        <span v-else class="loseEfficacy">{{ ms }}秒重新获取验证码</span>
      </el-form-item>
      <el-form-item>
        <div class="warp-btn">
          <el-button @click="resetForm('ruleForm')">取消</el-button>
          <el-button @click="submitForm('ruleForm')" type="primary">提交</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { Message } from 'element-ui';
import { url,api } from '@/utils/config';
import { putFun,postFun } from '@/utils/httpFun';
import { hex_md5 } from '@/utils/md5'; 
import axios from 'axios'
export default {
  data() {
    var oldPw = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入原密码'));
      } else {
        callback();
      }
    };
    var newPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('新的手机号不可为空'));
      } else {
        callback()
      }
    };
    return {
      ms:60,
      isCode:true,
      showBlock: false,
      ruleForm: {
        oldPhone: '',
        newPhone: '',
        checkPws: ''
      },
      rules: {
       
        newPhone: [
          { validator: newPhone, trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入规则的手机号码', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {

    //开启弹窗
    showBlockM(info) {
      console.log("信息", info)
      this.ruleForm.oldPhone = info.factoryUserVo.mobile
      this.showBlock = true
    },
    //提交登录
    submitForm(ruleForm) {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          return
        } else {
          this.submitBtn()
        }
      });
    },
    async submitBtn() {
      // let parms = {
      //   userId: this.$store.state.agentUser.userId,
      //   oldPassword: hex_md5(this.ruleForm.oldpws + ""),
      //   password: hex_md5(this.ruleForm.newpws + ""),
      //   loginType: 1
      // }
      // await putFun(url.UPDATASETPWD, parms, res => {
      //   if (res.data) {
      //     Message.success("修改成功")
      //   }
      //   this.resetForm("ruleForm")
      // })

      let param = {
        code:this.ruleForm.checkPws,
        newMobile:this.ruleForm.newPhone
      }

      

      postFun(api.other+'/account/updatePhone',{...param},res=>{
        console.log('更新手机号---',res);
        if(res.data){
          this.$message.success('修改成功')
          this.$parent.getMess();
        }
        this.closedBtn();
      })
    },
    getCode(){
      console.log('获取验证码')

      if(!!!this.ruleForm.newPhone){
        this.$message.warning('请输入手机号')
        return;
      }

      if(this.ruleForm.newPhone == this.ruleForm.oldPhone){
        this.$message.warning('新手机号不能与原手机号一致');
        return;
      }

    let ruel = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
    if(!ruel.test(this.ruleForm.newPhone)){
      this.$message.warning('手机号格式不正确');
      return false;
    }

    this.isCode = false;
      setInterval(() => {
        this.ms--;
        if(this.ms <= 0){
          this.isCode = true;
        }
      },1000)


      axios.get('/engineer-applet-service/common/sendCode?phone='+this.ruleForm.newPhone).then(res=>{
          if(!!res.data.data){
          
          }else{
            this.$mmessage.warning('网络繁忙'); 
          }
      }).catch(function (err) {
        this.$mmessage.warning('网络繁忙'); 
      });  
    },
    //重置输入框
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.closedBtn();
    },
    //关闭弹窗
    closedBtn() {
      this.showBlock = false
    },
  }
}
</script>
<style lang="scss" scoped>
 .code{
    color: rgb(60 130 255);
    position: absolute;
    width: 100px;
    right: 0;
  }
  .loseEfficacy{
    color: #ccc;
    position: absolute;
    right: 12px;
  }
.warp-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

::v-deep .el-dialog__header {
  height: 23px;
  background: #F8F9FA;
  box-shadow: 0px 1px 0px 0px rgb(237 238 240);
  border-radius: 12px 12px 0 0;
}

::v-deep .el-dialog {
  border-radius: 12px;
}

::v-deep .el-dialog__body {
  padding: 30px 20px 10px 10px;
}</style>
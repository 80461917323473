import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/pages/login'
import Index from "@/pages/index"
Vue.use(Router)

//项目管理
const PLATFORM= [
  { path: 'Platform', name: 'Platform', tag: '项目管理', component: () => import('@/pages/module-index/index.vue') }
]

//项目管理
const PROJECT = [
  { path: 'project', name: 'project', tag: '项目管理', component: () => import('@/pages/module-Project/index.vue') }
]
//账户角色管理
const ACCOUNT = [
  { path: 'Accounter', name: 'Accounter', tag: '账户管理', component: () => import('@/pages/module-accounter/accounter.vue') },
  { path: 'Role',      name: 'Role',      tag: '角色管理', component: () => import('@/pages/module-rolelists/index.vue') }
]
//代理商工程商
const ENGINEER = [
  { path: 'Agent',  name: 'Agent',  tag: '代理商管理', component: () => import('@/pages/module-agent/agent.vue') },
  { path: 'Engeer', name: 'Engeer', tag: '工程商管理', component: () => import('@/pages/module-engger/engger.vue') },
]

//物联网卡
const INTERENTCARD = [
  { path: 'Application',   name: 'Application',     tag: '网上申购',   component: () => import('@/pages/module-contentunion/contentunion-list/application.vue') },
  { path: 'PutStorage',    name: 'PutStorage',      tag: '进卡入库',   component: () => import('@/pages/module-contentunion/contentunion-list/putstorage.vue') },
  { path: 'StockCards',    name: 'StockCards',      tag: '卡库存管理', component: () => import('@/pages/module-contentunion/contentunion-list/stockcards.vue') },
  { path: 'NotCtivated',   name: 'NotCtivated',     tag: '出库未激活', component: () => import('@/pages/module-contentunion/contentunion-list/notctivated.vue') },
  { path: 'CardaCtivated', name: 'CardaCtivated',   tag: '出库已激活', component: () => import('@/pages/module-contentunion/contentunion-list/cardactivated.vue') },
  { path: 'Customer',      name: 'Customer',        tag: '客户管理',   component: () => import('@/pages/module-contentunion/contentunion-list/customer.vue') },
  { path: 'paidrenewed',   name: 'paidrenewed',     tag: '已续费管理', component: () => import('@/pages/module-contentunion/contentunion-list/paidrenewed.vue') },
  { path: 'notrenewed',    name: 'notrenewed',      tag: '待续费',     component: () => import('@/pages/module-contentunion/contentunion-list/notrenewed.vue') },
  { path: 'Cardconsult',    name: 'Cardconsult',      tag: '进卡入库',   component: () => import('@/pages/module-contentunion/contentunion-list/cardconsult.vue') },
]

//产品管理
const PRODUCTSHIMP = [
  { path: 'ProdcutCategory', name: 'ProdcutCategory', tag: '产品分类', component: () => import('@/pages/module-product/productType/index.vue') },
  { path: 'ProductAdd',      name: 'ProductAdd',      tag: '新增产品', component: () => import('@/pages/module-product/product-list/index.vue') },
  { path: 'Warehousing',     name: 'Warehousing',     tag: '设备入库', component: () => import('@/pages/module-product/product-list/module-putIn/warehousing.vue') },
  { path: 'Info',            name: 'Info',            tag: '设备详情', component: () => import('@/pages/module-product/product-list/module-info/info.vue') },
]

//出货管理
const SHIPMENT = [
  { path: 'WaitShipment',    name: 'WaitShipment',    tag: '待出货', component: () => import('@/pages/module-shipment/waitShipment/index.vue') },
  { path: 'AreadlyShipment', name: 'AreadlyShipment', tag: '已出货', component: () => import('@/pages/module-shipment/alreadyShipment/index.vue') },
  { path: 'inventory',       name: 'inventory',       tag: '出货单', component: () => import('@/pages/module-shipment/inventory/index.vue')},
]


//财务管理
const FINANCE = [
  { path: 'CostSituation',   name: 'CostSituation',     tag: '费用概况',       component: () => import('@/pages/module-financial/costSituation/index.vue')},
  { path: 'FeeManager',      name: 'FeeManager',        tag: '设备服务费管理', component: () => import('@/pages/module-financial/feeManager/index.vue')},
  { path: 'CostRecords',     name: 'CostRecords',       tag: '充值记录管理',   component: () => import('@/pages/module-financial/costRecords/index.vue')},
  { path: 'ShortVoice',      name: 'ShortVoice',        tag: '短信语音统计',   component: () => import('@/pages/module-financial/short-voice/index.vue')},
  { path: 'ConsumerRecords', name: 'ConsumerRecords',   tag: '设备消费记录',   component: () => import('@/pages/module-financial/consumer-records/index.vue')},
  { path: 'WalletRecords',   name: 'WalletRecords',     tag: '开票记录',       component: () => import('@/pages/module-financial/wallet-records/index.vue')},
]

//报警管理
const ALARM = [
  { path: 'AlarmProcessed', name: 'AlarmProcessed', tag: '报警待处理',     component: () => import('@/pages/module-alarm/alarm-processed/index.vue')},
  { path: 'AlarmAlreadyed', name: 'AlarmAlreadyed', tag: '报警已处理',     component: () => import('@/pages/module-alarm/alarm-alreadyed/index.vue')},
  { path: 'AlarmConfig',    name: 'AlarmConfig',    tag: '设备报警设置',   component: () => import('@/pages/module-alarm/alarm-config/index.vue')},
  { path: 'AlarmNotes',     name: 'AlarmNotes',     tag: '预警消息记录',   component: () => import('@/pages/module-alarm/alarm-notes/index.vue')},
  { path: 'Alarm',          name: 'Alarm',          tag: '我指派的报警',   component: () => import('@/pages/module-alarm/alarm-alarm/index.vue')},
  { path: 'AlarmMy',        name: 'AlarmMy',        tag: '指派给我的报警', component: () => import('@/pages/module-alarm/alarm-alarmMy/index.vue')},
]

//报警管理
const  PATROL = [
  { path: 'Reportlist',     name: 'Reportlist',     tag: '我上报的',     component: () => import('@/pages/module-partol/reportlist/index.vue') },
  { path: 'Unprolist',      name: 'Unprolist',      tag: '待处理',       component: () => import('@/pages/module-partol/unprolist/index.vue') },
  { path: 'Procelist',      name: 'Procelist',      tag: '已处理',       component: () => import('@/pages/module-partol/procelist/index.vue') },
]

// 系统设置
const SYSTEMSETUP = [
  {path: 'Enterprise', name:'Enterprise',tag:'企业信息',component: () => import ('@/pages/module-system/enterprise/index.vue')},
]

// 公告管理
const ANNOUNCEMENT = [
  {path:'Announcentent',name:'Announcentent',tag:'公告管理',component: () => import ('@/pages/module-announcement/index.vue')}
]
// 日志管理
const LOG = [
  {path:'Operatelog',   name:'Operatelog',   tag:'日志管理',component: () => import ('@/pages/module-log/operate-log/index.vue')}
]
// 消息中心
const MESSAGE = [
  {path:'Message',name:'Message',tag:'消息中心',component: () => import ('@/pages/module-message/index.vue')}
]
// IOT开发
const IOT = [
  {
    path:'IotDeveloper',name:'IotDeveloper',tag:'iot开发',component: () => import ('@/pages/module-iot/index.vue')
  }
]
const router = new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/Index'
    },
    {
      path:'/Screen',
      name:'Screen',
      component:()=>import('@/pages/module-screen/index.vue')
    },
    {
      path: '/Login',
      name: 'Login',
      component: Login,
    },
    {
      path: '/Index',
      name: 'Index',
      component: Index,
      children: [...PLATFORM,...PROJECT, ...ACCOUNT, ...ENGINEER, ...INTERENTCARD, ...PRODUCTSHIMP,...SHIPMENT,...FINANCE,...ALARM,...PATROL,...SYSTEMSETUP,...ANNOUNCEMENT,...LOG,...MESSAGE,...IOT]
    }
  ]
})

// 前置守卫
router.beforeEach((to, from, next) => {

  // console.group('%c%s', 'color:rgb(31, 189, 241)', '前置守卫')
  // console.table('to:', to);
  // console.table('from:', from);
  // console.groupEnd();

  // if(to.query.isRouter){
  //   next(false);
  //   return;
  // }
  // if(to.name == 'Screen' && from.path == '/'){
  //   to.query.isRouter = true;
  //   return;
  // }

  let isPathBoolean = false;
  for (let i = 0; i < router.options.routes.length; i++) {
    let item = router.options.routes[i];
    if (to.name != null && to.name == item.name) {
      isPathBoolean = true;
      break;
    } else {
      isPathBoolean = isPathExist(to, item);
      if (isPathBoolean) break;
    }
  }
  if (isPathBoolean) {

    // console.log('========路由=',to,(to.fullPath.includes('mapBlock')))

    // if(to.fullPath.includes('mapBlock')){
    //   next();
    // }
   if (to.name == 'Index' && from.name != 'Login') {
      next({ name: from.name });
    } else {
      next();
    }
  } else {
    next({ name: from.name });
  }
})

function isPathExist(to, item) {
  if (!item.children) return false;
  let isFun = false;
  for (let i = 0; i < item.children.length; i++) {
    let childeItem = item.children[i]
    if (to.name == childeItem.name) {
      return true;
    } else {
      isFun = isPathExist(to, childeItem)
    }
  }
  return isFun;
}
export default router;
import store from './store'
import citYvalue from './city'
import * as XLSX from 'xlsx'
const req = require.context('@/assets/custom-icon/systemIcon', false, /\.svg$/)
const requireAll = requireContext => requireContext.keys()
const re = /\.\/(.*)\.svg/

//获取浏览器窗口的宽高
export function getWindowMess(){
    let clientHeight = document.documentElement.clientHeight;
    let clientWidth = document.documentElement.clientWidth;
    store.commit({
        type: "setWindowBtn",
        clientHeight: clientHeight,
        clientWidth: clientWidth
    })
}

//获取日期和时间 不含周几
export function getDateAndTime(nowDate){
    var nowdate = new Date(nowDate);
    var year = nowdate.getFullYear(),
        month = nowdate.getMonth() + 1,
        date = nowdate.getDate(),
        day = nowdate.getDay(),
        week = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
        h = nowdate.getHours(),
        m = nowdate.getMinutes(),
        s = nowdate.getSeconds(),
        hour = h < 10 ? "0" + h : h 
        m = m < 10 ? "0" + m : m 
        s = s < 10 ? "0" + s : s 
    return year + "-" + ((month+"").length == 1?"0"+month:month) + "-" + ((date+"").length == 1?"0"+date:date) + " " + hour +":" + m + ":" + s;
}

//获取日期
export function getDateTime(){
    var nowdate = new Date();
    var year = nowdate.getFullYear(),
        month = nowdate.getMonth() + 1,
        date = nowdate.getDate(),
        day = nowdate.getDay(),
        week = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
        h = nowdate.getHours(),
        m = nowdate.getMinutes(),
        s = nowdate.getSeconds(),
        h = h < 10 ? "0" + h : h 
        m = m < 10 ? "0" + m : m 
        s = s < 10 ? "0" + s : s 
    return year + "年" + month + "月" + date + "日 " + week[day] + " " + h +":" + m + ":" + s;
}

//获取日期
export function getDateTimeTwo(date = null){
    var nowdate = date ? new Date(date) : new Date();
    var year = nowdate.getFullYear(),
        month = nowdate.getMonth() + 1,
        date = nowdate.getDate(),
        
        h = nowdate.getHours(),
        m = nowdate.getMinutes(),
        s = nowdate.getSeconds(),
        h = h < 10 ? "0" + h : h 
        m = m < 10 ? "0" + m : m 
        s = s < 10 ? "0" + s : s 
    return year + "-" + (month.toString().length === 1 ? `0${month}` : month) + "-" + (date.toString().length === 1 ? `0${date}` : date)  +  " " + h +":" + m + ":" + s;
}

//获取icon图片的尾缀
export function Iconimg(){
    let arrayList = []
    requireAll(req).map(i => {
        arrayList.push(i.match(re)[1])
    })
    return arrayList
}

export const suffix = (val, sign = true) => {
  const _sign = parseFloat(val || "0") < 0 ? "-" : "";
  val = val ? Number(Math.abs(parseFloat(val || "0"))).toFixed(2) : "0.00";
  let ht = '';
  const hrr = val.split('.')[0];
  let hrr1 = val.split('.')[1];
  let index = 0;
  let sIndex = hrr.length % 3;
  // hrr1判断数位
  if (hrr1 && hrr1.length == 1) {
    hrr1 = hrr1 + '0';
  }

  if (hrr.length > 3) {
    while (index < hrr.length) {
      if (sIndex != 0) {
        ht += hrr.slice(index, sIndex) + ',';
        index = sIndex;
        sIndex += 3;
      } else {
        ht += hrr.slice(index, index + 3) + ',';
        index += 3;
      }
    }
    if (ht[ht.length - 1] == ',') {
      ht = ht.slice(0, ht.length -1);
    }
    return hrr1 ? _sign + ht + (sign ? '.' + hrr1 : '') : _sign + ht + (sign ? '.00' : '');
  } else {
    return hrr1 ? _sign + hrr + (sign ? '.' + hrr1 : '') : _sign + val + (sign ? '.00' : '');
  }
};

export function changeKay(objAry) {
    if (objAry != null) {
        objAry.forEach((item) => {
            item["companyId"]= item.currentDepart.companyId
            item["createTime"]= item.currentDepart.createTime
            item["departStatus"]= item.currentDepart.departStatus
            item["descr"]= item.currentDepart.descr
            item["id"]= item.currentDepart.id
            item["isDel"]= item.currentDepart.isDel
            item["level"]= item.currentDepart.level
            item["name"]= item.currentDepart.name
            item["operator"]= item.currentDepart.operator
            item["parentId"]= item.currentDepart.parentId
            item["unit_id"]= item.currentDepart.unit_id
            item["updateTime"]= item.currentDepart.updateTime
            delete item.currentDepart;
            changeKay(item.childDeparts);
        });
        
        console.log(objAry) 
    }
}

export const isFullScreen = () => {
    return  !! (
        document.fullscreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreen ||
        document.webkitFullScreen ||
        document.msFullScreen
    );
}

// 进入全屏
export const entryFullScreen = (element) => {
    if (document.mozFullScreenEnabled) {
        return Promise.reject(new Error("全屏模式被禁用"));
    }
    let result = null;
    if (element.requestFullscreen) {
        result = element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
        result = element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
        result = element.msRequestFullscreen();
    } else if (element.webkitRequestFullscreen) {
        result = element.webkitRequestFullScreen();
    }
    return result || Promise.reject(new Error("不支持全屏"));
};

// 退出全屏
export const exitFullScreen = () => {
    if(document.exitFullScreen) {
        document.exitFullScreen();
    } else if(document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
    } else if(document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
    } else if(document.msExitFullscreen) {
        document.msExitFullscreen();
    }
};

// vue指令：只有数字输入
function onInput(el, ele, binding, vnode) {
  function handle() {
    // 只保留数字
    ele.value = ele.value.replace(/[^\d]/g, '');
  } 

  return handle
}
export const numberInput = {
    bind(el, binding, vnode) {
        const ele = el.tagName === 'INPUT' ? el : el.querySelector('input');
        ele.addEventListener('input', onInput(el, ele, binding, vnode), false);
    },
}

// 全局cache
export const cache = {
    get(key) {
        const session = sessionStorage.getItem(key);
        if (session) {
            return JSON.parse(sessionStorage.getItem(key));
        } else {
            return undefined;
        }
    },
    set(key, val) {
        sessionStorage.setItem(key, JSON.stringify(val));
    },
    clear(key) {
        if (key) {
            sessionStorage.removeItem(key);
        } else {
            sessionStorage.clear();
        }
    }
};

// 获取公司名
export const getCompanyName = () => app.companyName;

// 下载文件：xls，xlsx，doc，docx，pdf
export const downloadFile = (stream, name, suffix) => {
    const DOWNLOAD_TYPE_MAP = {
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        pdf: 'application/pdf'
    };
    if (!DOWNLOAD_TYPE_MAP[suffix]) {
        throw new Error('请传入文件下载的格式后缀，eg：xls，xlsx，doc，docx，pdf');
    }
    const blob = new Blob([stream], {
        type: DOWNLOAD_TYPE_MAP[suffix],
    });
    const fileName = `${name}.${suffix}`;
    let link = document.createElement('a');
    document.body.appendChild(link);
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
};

// 下载图片
export const downloadImage = (imgsrc, name) => {
    let image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute('crossOrigin', 'anonymous');
    image.onload = function() {
        let canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        let context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, image.width, image.height);
        let url = canvas.toDataURL('image/png');
        let a = document.createElement('a');
        let event = new MouseEvent('click');
        a.download = name || 'photo';
        a.href = url;
        a.dispatchEvent(event);
    };
    image.src = imgsrc;
};


//监听扫码枪的事件
export function codeGun() {
    return new Promise((resolve, reject) => {
        // 监听按键
        var code = '';
        var lastTime, nextTime; // 上次时间、最新时间
        var lastCode, nextCode; // 上次按键、最新按键
        document.onkeydown = function(e) {
            // 获取按键
            if (window.event) { // IE
                nextCode = e.keyCode;
            } else if (e.which) { // Netscape/Firefox/Opera
                nextCode = e.which;
            }
            // 如果触发了回车事件(扫码结束时间)
            if (nextCode === 13) {
                if (code.length < 3) return // 手动输入的时间不会让code的长度大于2，所以这里只会对扫码枪有
                // this.codeFind(code) // 获取到扫码枪输入的内容，做别的操作
                console.log("数据",code)
                code = '';
                lastCode = '';
                lastTime = '';
                return;
            }
            nextTime = new Date().getTime(); // 记录最新时间
            if (!lastTime && !lastCode) { // 如果上次时间和上次按键为空
                code += e.key; // 执行叠加操作
            }
            // 如果有上次时间及上次按键
            if (lastCode && lastTime && nextTime - lastTime > 30) { // 当扫码前有keypress事件时,防止首字缺失
                code = e.key;
            } else if (lastCode && lastTime) {
                code += e.key;
            }
            lastCode = nextCode;
            lastTime = nextTime;
            console.log('last code', lastCode, code);
            resolve(code);
        };
    });
}

//截取 - 以前的文字
export function deviceName(value){
    if(!value){
		return ""
	 }
	if(value.indexOf('-')==-1){
		return value
	}
    return  value.slice(0,value.indexOf('-'))
}

//获取对应的城市code
export function getCitycode(ev){
	let valText = ev.split(',')
    let codeArray = []
	if(valText[0]=="上海市" || valText[0]=="天津市" || valText[0]=="北京市" || valText[0]=="重庆市"){
		Mdirectly(valText,res=>{
			codeArray = res
		})
		
	}else{
		for(var i in citYvalue){
			if(citYvalue[i].label==valText[0]){
				codeArray.push(citYvalue[i].value)
				let cityList =  citYvalue[i].children
				for(var j in cityList){
					if(cityList[j].label==valText[1]){
						codeArray.push(cityList[j].value)
						let streeList = cityList[j].children 
						for(var h in streeList){
							if(streeList[h].label == valText[2]){
								codeArray.push(streeList[h].value)
							}
						}
					}
				}
			}
		}
	}
   return codeArray
	// console.log("换区对应的code",codeArray)
}

export function  Mdirectly(val,callback){
	
	if(val[0]=="上海市" || val[0]=="天津市" || val[0]=="北京市" || val[0]=="重庆市"){
		let list = []
		for(var i in citYvalue){
			if(citYvalue[i].label == val[0]){
				let listC = citYvalue[i].children
				 for(var j in listC){
					 let listZ = listC[j].children
					 for(var h in listZ){
						 list.push({p:citYvalue[i].label,pcode:citYvalue[i].value,c:listC[j].label,ccode:listC[j].value,z:listZ[h].label,zcode:listZ[h].value})
					 } 
				 }
			}
		}
		for(var n in list){
			if(list[n].z==val[1]){
				callback([list[n].pcode,list[n].ccode,list[n].zcode])
				return 
			}
		}
	}
}

// 获取excle读取出来的对象
export function getExcelData(event,resolve,reject){
	// 拿取文件对象
    var f = event
    // 用FileReader来读取
    var reader = new FileReader()
    // 重写FileReader上的readAsBinaryString方法
    FileReader.prototype.readAsBinaryString = (f)=>{
      var binary = ''
      var wb // 读取完成的数据
      var outdata // 你需要的数据
      var reader = new FileReader()
      reader.onload = (e) => {
       
        // 读取成Uint8Array，再转换为Unicode编码（Unicode占两个字节）
        var bytes = new Uint8Array(reader.result)
        var length = bytes.byteLength
        for (var i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i])
        }
        // 接下来就是xlsx了，具体可看api
        wb = XLSX.read(binary, {
          type: 'binary'
        })
        outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
        // 自定义方法向父组件传递数据

        if(outdata.length >= 5001){
            this.$message.warning("不能超过五千条")
            reject('无法上传')
          }else{
            resolve(outdata)
          }
      }
      reader.readAsArrayBuffer(f)
    }
    reader.readAsBinaryString(f)
}

//获取Excle的数据
export function exportData(keyVal,event,resolve,reject,callback){
      // 拿取文件对象
      var f = event
      // 用FileReader来读取
      var reader = new FileReader()
      // 重写FileReader上的readAsBinaryString方法
      FileReader.prototype.readAsBinaryString = (f)=>{
        var binary = ''
        var wb // 读取完成的数据
        var outdata // 你需要的数据
        var reader = new FileReader()
        reader.onload = (e) => {
         
          // 读取成Uint8Array，再转换为Unicode编码（Unicode占两个字节）
          var bytes = new Uint8Array(reader.result)
          var length = bytes.byteLength
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i])
          }
          // 接下来就是xlsx了，具体可看api
          wb = XLSX.read(binary, {
            type: 'binary'
          })
          outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
          // 自定义方法向父组件传递数据

          if(outdata.length >= 5001){
              this.$message.warning("不能超过五千条")
              reject('无法上传')
            }else{
              let  serialNos = outdata.map(item=>item[keyVal]);
              callback(serialNos)
            
              reject('未超过限制')
            }
        }
        reader.readAsArrayBuffer(f)
      }
      reader.readAsBinaryString(f)
}


export default {
    getWindowMess,
    getDateTime,
    Iconimg,
    suffix,
    numberInput,
    cache,
    codeGun,
    deviceName,
    getCompanyName,
    isFullScreen,
    entryFullScreen,
    exitFullScreen,
    downloadFile,
    downloadImage,
    getDateTimeTwo,
    getDateAndTime,
    getExcelData
};

